/** @jsxImportSource theme-ui */
import { Box } from '@theme-ui/components';
import { Icon } from 'components/Common/Icon';

export const SectionProgress = ({ complete, completedCount, totalCount, ...props }) => {
    if (complete) {
        return <Icon icon="tick-blue" color="primary" size="20px" mr={2} />;
    }

    return (
        <Box
            sx={{
                bg: 'grayLight',
                color: 'primary',
                fontWeight: 600,
                borderRadius: '20px',
                padding: '3px 12px',
                fontSize: 0,
                display: 'inline-flex',
                alignItems: 'center',
                justifyContent: 'center',
                mr: 2,
            }}
        >
            {completedCount} of {totalCount}
        </Box>
    );
};

SectionProgress.defaultProps = {
    complete: false,
    completedCount: 0,
    totalCount: 0,
};
