import { Box, Text } from '@theme-ui/components';
import { useField } from 'formik';
import { useRef } from 'react';
import SignaturePad from 'react-signature-canvas';

import { Button } from 'components/Common/Button';
import { Error } from '../Error';

export const Signature = ({ text, name, apiName, sx, ...props }) => {
    const sigCanvasRef = useRef({});

    // eslint-disable-next-line
    const [field, meta, { setValue }] = useField(name);

    const clear = () => {
        sigCanvasRef.current.clear();
        setValue(false);
    };

    const setSignatureValue = () => {
        setValue(sigCanvasRef.current.getTrimmedCanvas().toDataURL('image/png'));
    };

    return (
        <Box sx={{ m: 'auto', mb: 4, width: ['270px', '330px'], ...sx }}>
            <Error text={meta.error} isVisible={meta.touched && meta.error} />

            <Box
                sx={{
                    bg: '#f3f5f7',
                    border: '1px solid #ebeff1',
                    borderRadius: 4,
                    height: 70,
                    position: 'relative',
                    mb: field.value ? 3 : 0,
                }}
            >
                {!field.value && (
                    <Text
                        sx={{
                            position: 'absolute',
                            color: '#9CADB8',
                            top: '50%',
                            left: 3,
                            transform: 'translateY(-50%)',
                            pointerEvents: 'none',
                        }}
                    >
                        Signature
                    </Text>
                )}

                <SignaturePad
                    canvasProps={{ width: '330', height: 70, className: 'sigCanvas' }}
                    ref={sigCanvasRef}
                    onEnd={setSignatureValue}
                />
            </Box>

            <Box>
                {field.value && (
                    <Button
                        size="sm"
                        type="button"
                        variant="bordered"
                        mx={1}
                        onClick={clear}
                    >
                        Clear
                    </Button>
                )}
            </Box>
        </Box>
    );
};

Signature.defaultProps = {
    name: '',
};
