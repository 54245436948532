import { useContext, memo } from 'react';
import { Grid } from '@theme-ui/components';
import { useParams } from 'react-router';
import { useFormikContext } from 'formik';

import { AppContext } from 'context/AppContext';
import { MarkdownContext } from 'context/MarkdownContext';
import { checkCondition } from '../FormSections/helpers';

// import { handleFieldChange } from './helpers';
import { useSubmitQuoteData } from 'pages/Acceptance/hooks/useSubmitQuoteData';
import { FormField } from './FormField';

const FormFieldsMemoized = ({
    fields,
    repeaterName,
    repeatIndex,
    groupFieldName,
    repeater,
    ...props
}) => {
    const { quoteUuid } = useParams();
    const { values } = useFormikContext();
    const app = useContext(AppContext);
    const markdown = useContext(MarkdownContext);
    const submitQuoteData = useSubmitQuoteData();

    const renderField = ({ type, name, condition, mdContent, hidden, ...rest }) => {
        if (!type) {
            return 'Error: Missing field type';
        }

        if (hidden) {
            return '';
        }

        if (condition) {
            if (repeatIndex !== false){
                condition = { ...condition, field: condition.field.replace('{{repeatIndex}}', repeatIndex)};
            }
            // if doesn't meet condition, don't render field group
            if (!checkCondition(condition, values)) {
                return '';
            }
        }

        const getFieldName = () => {
            // need to construct the field name differently if it's a repeater vs normal field (with or without group property)
            if (['repeater', 'file'].includes(type)) {
                return `${name}`;
            }

            // check if there's an override for group field name
            let groupFieldNameVar = rest.groupFieldName
                ? rest.groupFieldName
                : groupFieldName;

            // might not need this
            if (repeater) {
                return `${repeaterName}[${repeatIndex}][${name}]`;
            }

            if (groupFieldNameVar || repeatIndex === false) {
                return `${groupFieldNameVar}.${name}`;
            }

            if (repeatIndex !== false) {
                return `${repeaterName}[${repeatIndex}][${name}]`;
            }

            return name;
        };

        const getApiFieldName = () => {
            // need to construct the field name differently if it's a repeater vs normal field (with or without group property)
            if (['repeater', 'file'].includes(type)) {
                return `${name}`;
            }

            // check if there's an override for group field name
            let groupFieldNameVar = rest.groupFieldName
                ? rest.groupFieldName
                : groupFieldName;

            if (groupFieldNameVar || repeatIndex === false) {
                return `${groupFieldNameVar}[${name}]`;
            }

            if (repeatIndex !== false) {
                return `${repeaterName}[${repeatIndex}][${name}]`;
            }

            return name;
        };

        const handleFieldChange = async (args) => await submitQuoteData.mutateAsync(args);

        // Set financier content terms and conditions required

        mdContent =
            mdContent === 'financier' ? `financier-${app.state.financier_id}` : mdContent;

        const fieldProps = {
            key: getFieldName(),
            name: getFieldName(),
            apiName: getApiFieldName(),
            mdContent: mdContent ? markdown[mdContent] : false,
            // remove on change for repeater sub-fields, all values to be sent together
            onChange: !repeaterName
                ? (args) => handleFieldChange({ ...args, quoteUuid })
                : () => {
                      return false;
                  },
            ...rest,
        };

        return <FormField type={type} fieldProps={fieldProps} />;
    };

    const renderFieldRows = (fields) => {
        if (Array.isArray(fields)) {
            return fields.map((field, index) => {
                return (
                    <Grid
                        key={index}
                        columns={[
                            1,
                            null,
                            field.length > 2 ? field.length : 1,
                            field.length,
                        ]}
                        gap={2}
                    >
                        {renderFieldRows(field, index)}
                    </Grid>
                );
            });
        } else {
            return renderField(fields);
        }
    };

    return renderFieldRows(fields);
};

FormFieldsMemoized.defaultProps = {
    fields: [],
    repeat: false,
    repeatIndex: false,
    repeater: false,
};

export const FormFields = memo(FormFieldsMemoized);
