import { Box } from '@theme-ui/components';
import { motion, AnimatePresence } from 'framer-motion';

import { StageHeader } from '../StageHeader';
import { FormSections } from '../FormSections';
import { SubmitStage } from '../SubmitStage';
import { ContentBox } from 'components/Common/ContentBox';
import { useValidateStages } from 'pages/Dashboard/hooks/useValidateStages';

const BoxMotion = motion(Box);

export const StageMobile = ({
    stage,
    sx,
    index,
    isLastStage,
    isSelected,
    onStageClick,
    isSubmission,
    dataComplete,
    quoteData,
    onClose,
    ...props
}) => {
    const completeCount = useValidateStages(stage, quoteData);

    return (
        <>
            <AnimatePresence initial={false}>
                <BoxMotion
                    key={index}
                    variants={{
                        open: {
                            // top: 'calc(-100vh + 480px)',
                            // conditional style if submit button is present
                            height: `calc(100vh - ${dataComplete ? '195px' : '70px'})`,
                            zIndex: 100,
                            bottom: isSubmission ? '-95px' : 0,
                        },
                        closed: {
                            zIndex: 'auto',
                            top: index * 84,
                        },
                    }}
                    animate={isSelected ? 'open' : 'closed'}
                    initial="closed"
                    transition={
                        {
                            // duration: 1,
                        }
                    }
                    sx={{
                        cursor: 'pointer',
                        position: 'absolute',
                        borderTopLeftRadius: 30,
                        borderTopRightRadius: 30,
                        bg: 'white',
                        boxShadow: '0px 4px 30px rgba(40, 39, 129, 0.15)',
                        width: '100%',
                        mt: 'auto',
                        zIndex: 'auto',
                        WebkitTapHighlightColor: 'transparent',
                        ...sx,
                    }}
                    {...props}
                >
                    {!isSubmission && (
                        <StageHeader
                            tabIndex="0"
                            index={index}
                            stage={stage}
                            key={index}
                            onKeyPress={(e) => {
                                if(e.key === 'Enter'){
                                    onStageClick({ stage, index })
                                }
                            }}
                            onClick={() => onStageClick({ index })}
                            completeCount={completeCount}
                            sx={{
                                '&:focus': {
                                    outline: 'none'
                                }
                            }}
                        />
                    )}

                    <AnimatePresence initial={false}>
                        {isSelected && (
                            <BoxMotion
                                key="content"
                                initial="collapsed"
                                animate="open"
                                exit="collapsed"
                                variants={{
                                    open: {
                                        opacity: 1,
                                        // height: '100%',
                                        position: 'relative',
                                        zIndex: 9992,
                                        pb: '180px',
                                    },
                                    collapsed: { opacity: 0, height: 0 },
                                }}
                                sx={{ bg: 'white' }}
                            >
                                {!isSubmission ? (
                                    <FormSections stage={stage} />
                                ) : (
                                    <ContentBox>
                                        <SubmitStage onClose={onClose} />
                                    </ContentBox>
                                )}
                            </BoxMotion>
                        )}
                    </AnimatePresence>
                </BoxMotion>
            </AnimatePresence>
        </>
    );
};
