import { Box } from '@theme-ui/components';

export const ContentBox = ({ children, sx, innerRef, ...props }) => {
    return (
        <Box
            sx={{ bg: 'white', borderRadius: 30, p: 4, ...sx }}
            ref={innerRef}
            {...props}
        >
            {children}
        </Box>
    );
};
