/** @jsxImportSource theme-ui */
import { Box, Grid, Text } from '@theme-ui/components';
import { Themed } from '@theme-ui/mdx';

import { ContentBox } from '../ContentBox';

export const Stats = ({ items, dollarSign, ...props }) => {
    if (!items.length) {
        return '';
    }

    return (
        <ContentBox
            sx={{
                py: [4, 5],
                px: [3, 6],
            }}
            {...props}
        >
            <Grid gap={3} columns={[2, '', '', 4]}>
                {items.map((item, index) => (
                    <Box
                        key={index}
                        sx={{
                            display: 'inline-flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Themed.h2 sx={{ m: 0 }}>
                            {dollarSign && (
                                <Text as="sup" color="secondary" sx={{ fontSize: 1 }}>
                                    $
                                </Text>
                            )}
                            {item.heading}
                        </Themed.h2>

                        <Text as="p" variant="label">
                            {item.subheading}
                        </Text>
                    </Box>
                ))}
            </Grid>
        </ContentBox>
    );
};

Stats.defaultProps = {
    items: [],
    dollarSign: true,
};
