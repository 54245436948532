import { useContext, useEffect, useState } from 'react';
import { Box } from '@theme-ui/components';
import { Button } from 'components/Common/Button';
import { useBreakpointIndex } from '@theme-ui/match-media';

import { StageMobile } from '../StageMobile';
import { StageDesktop } from '../StageDesktop';
import { ContentBox } from 'components/Common/ContentBox';
import { SubmitApplicationButton } from './components/SubmitApplicationButton';
import { AppContext } from 'context/AppContext';
import { useIsComplete } from 'hooks/useIsComplete';
import { useValidateQuote } from 'pages/Acceptance/hooks/useValidateQuote';

export const Stages = ({ quoteData, ...props }) => {
    const [activeStageIndex, setActiveStageIndex] = useState(false);
    const { isComplete } = useIsComplete(quoteData);
    const breakpointIndex = useBreakpointIndex();
    const validateQuote = useValidateQuote();

    const {
        dispatch,
        state: { formData },
    } = useContext(AppContext);

    useEffect(() => {
        dispatch({ type: 'CHECK_FORM_STAGES', quoteData });
        // eslint-disable-next-line
    }, [quoteData]);

    const handleStageClick = ({ index, submission }) => {
        if (index === false) {
            setActiveStageIndex(false);
        } else if (submission) {
            setActiveStageIndex(formData.length - 1);
        } else {
            setActiveStageIndex(activeStageIndex === index ? false : index);
        }

        dispatch({
            type: 'SET_ACTIVE',
            payload: { stage: activeStageIndex === index ? false : index },
        });
    };

    const handleClose = () => {
        setActiveStageIndex(false);
    };

    const handleValidate = async (e) => {
        e.preventDefault();
        quoteData = await validateQuote.mutateAsync(quoteData.uuid);
    };

    if (!formData.length) {
        return;
    }

    return (
        <>
            <Box
                sx={{
                    position: 'relative',
                    height: ['278px', '278px', null, 'auto'],
                    mt: ['auto', null, null, '0'],
                    top: '5px',
                }}
            >
                <ContentBox sx={{ px: [0, null, null, 3], maxWidth: 580, mx: '0' }}>
                    {formData.map((stage, index) => {
                        const stageProps = {
                            key: index,
                            index,
                            stage: stage,
                            isSelected: index === activeStageIndex,
                            isFirst: index === 0,
                            /* don't count the final submission stage */
                            isLastStage: formData.length === index + 2,
                            isSubmission: stage.submission,
                            // dataComplete means whole form complete
                            dataComplete: false,
                            onStageClick: handleStageClick,
                            onClose: handleClose,
                            quoteData: quoteData,
                            setActiveStageIndex,
                        };

                        return breakpointIndex > 2 ? (
                            <StageDesktop {...stageProps} />
                        ) : (
                            <StageMobile {...stageProps} />
                        );
                    })}
                    {/* Desktop */}
                    {breakpointIndex > 2 &&
                        <>
                        {isComplete ? (
                            <SubmitApplicationButton
                                sx={{ mt: 2 }}
                                isComplete={isComplete}
                                onStageClick={handleStageClick}
                                stageIndex={activeStageIndex + 1}
                                isLastStage={formData.length === activeStageIndex + 2}
                            />
                        ) : (
                            <Box
                                sx={{
                                    bg: 'white',
                                    textAlign: ['center', null, null, 'left'],
                                    position: ['fixed', null, null, 'relative'],
                                    bottom: 0,
                                    zIndex: 10,
                                    px: 4,
                                    width: ['100%', null, null, 'auto'],
                                    py: [3, null, null, 1]
                                }}
                            >
                                <Button
                                    sx={{ width: ['100%', null, null, 'auto'] }}
                                    onClick={handleValidate}
                                >
                                    {'Save Current Application'}
                                </Button>
                            </Box>
                        )}
                        </>
                    }
                </ContentBox>
            </Box>

            {/* Mobile */}
            {breakpointIndex <= 2 &&
                <>
                    {isComplete ? (
                        <SubmitApplicationButton
                            isComplete={isComplete}
                            onStageClick={handleStageClick}
                            stageIndex={activeStageIndex + 1}
                            isLastStage={formData.length === activeStageIndex + 2}
                        />
                    ) : (
                        <Box
                            sx={{
                                bg: 'white',
                                textAlign: ['center', null, null, 'left'],
                                position: ['fixed', null, null, 'relative'],
                                bottom: 0,
                                zIndex: 10,
                                px: 4,
                                width: ['100%', null, null, 'auto'],
                                py: [3, null, null, 1]
                            }}
                        >
                            <Button
                                sx={{ width: ['100%', null, null, 'auto'] }}
                                onClick={handleValidate}
                            >
                                {'Save Current Application'}
                            </Button>
                        </Box>
                    )}
                </>
            }
        </>
    );
};

Stages.defaultProps = {
    data: [],
};
