import { Switch, Route, useRouteMatch, useParams, Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useQuote } from 'pages/Acceptance/hooks/useQuote';
import { LoadingScreen } from 'components/Common/LoadingScreen';
import { Acceptance } from 'pages/Acceptance';
import { Dashboard } from 'pages/Dashboard';
import { Confirmation } from './Confirmation';
import { QuoteStatus } from 'utils/constants';

export const Quote = () => {
    const match = useRouteMatch();
    const { quoteUuid } = useParams();

    const { isLoading, isError, data: quoteData, error } = useQuote({
        quoteUuid,
        getToken: true,
    });

    if (isLoading) {
        return <LoadingScreen />;
    }

    if (isError) {
        console.log(error);
        toast.error(error.message, {
            toastId: 'quote-load-error',
        });
        return <Redirect to="/" />;
    }

    // if quote is in-progress
    const renderComponent = () => {
        switch (quoteData?.quote_status_id) {
            case QuoteStatus.accepted:
                return <Confirmation quoteData={quoteData} />;

            case QuoteStatus.in_progress:
            case QuoteStatus.data_completed:
                return <Dashboard quoteData={quoteData} />;

            case QuoteStatus.archived:
            case QuoteStatus.dealer_quotes:
                return <Redirect to="/" />;

            default:
                return <Acceptance quoteData={quoteData} />;
        }
    };

    return (
        <Switch>
            <Route path={`${match.path}/:quoteUuid`} exact>
                {renderComponent()}
            </Route>

            <Redirect to="/dashboard" />
        </Switch>
    );
};
