import ReactSelect from 'react-select';
import { useField, useFormikContext } from 'formik';
import { Box } from 'theme-ui';

import { Label } from '../Label';
import { customStyles } from './styled';
import { Error } from '../Error';

export const Select = ({ options, name, apiName, label, required, topLabel, noLabel, className, isReadonly, onChange, questionId, icon, includeEmpty = false, emptyLabel = null, ...props }) => {
    const { setFieldValue } = useFormikContext();
    // eslint-disable-next-line
    const [field, meta] = useField(name);

    isReadonly = isReadonly === undefined ? false : isReadonly;
    let selectOptions = options;
    if (includeEmpty && Array.isArray(options)) {
        selectOptions = options.slice();
        selectOptions.unshift({ label: emptyLabel ?? '-- Please select --', value: '' });
    }

    //set default value in formik if it's provided
    // useEffect(() => {
    //     props.defaultValue &&
    //         props.defaultValue.value &&
    //         setFieldValue(name, props.defaultValue.value);
    //     // eslint-disable-next-line
    // }, [options]);

    const findValueFromOptions = (value) => {
        return Array.isArray(selectOptions)
            ? selectOptions.find((option) => {
                  return option.value === value;
              })
            : {};
    };

    const handleOnChange = (option) => {
        setFieldValue(name, option ? option.value : '');
        onChange && onChange({ name: apiName, value: option.value });
    };

    const stylesOverride = {
        ...customStyles,
    };

    return (
        <Box sx={{ position: 'relative', mb: 3 }} className={className}>
            {label && <Label htmlFor={name} text={`${label}${required ? '*' : ''}`} sx={{ position: 'absolute', zIndex: 1, left: 15, top: '7px' }} />}

            <Error
                text={meta.error}
                isVisible={meta.touched && meta.error}
                sx={{
                    position: 'absolute',
                    right: '13px',
                    top: '7px',
                }}
            />

            <ReactSelect
                name={name}
                styles={stylesOverride}
                closeMenuOnSelect={true}
                components={{ IndicatorSeparator: () => null }}
                options={selectOptions}
                invalid={meta.touched && meta.error}
                isDisabled={isReadonly}
                isSearchable={!isReadonly}
                menuIsOpen={isReadonly ? false : undefined}
                onChange={handleOnChange}
                placeholder=""
                value={findValueFromOptions(field.value)}
                menuShouldScrollIntoView={true}
                {...props}
            />
        </Box>
    );
};
