import * as Yup from 'yup';

export const base64Validation = Yup.string().matches(
    /(data:image)+/i,
    'Please provide your signature'
);

export const agreeTermsValidation = Yup.boolean()
    .required('This field must be accepted.')
    .oneOf([true], 'This field must be accepted.');

export const referencesValidation = {
    name: Yup.string().nullable().required('Required'),
    address_1: Yup.string().nullable().required('Required'),
    phone: Yup.string().nullable().required('Required'),
    suburb: Yup.string().nullable().required('Required'),
    postcode: Yup.string().nullable().required('Required'),
    state: Yup.string().nullable().required('Required'),
    country_id: Yup.string().nullable().required('Required'),
};

export const grabFirstError = (errors) => {
    if (typeof errors === 'object' && errors !== null){
        for (let error in errors) {
            if (errors.hasOwnProperty(error)) {
                if (errors[error][0] !== ''){
                    return errors[error][0];
                }
            }
        }
    }
    return '';
}
