/** @jsxImportSource theme-ui */
import { Box, Text } from '@theme-ui/components';
import { memo } from 'react';
import { useParams } from 'react-router';

import { Button } from 'components/Common/Button';
import { useQuote } from 'pages/Acceptance/hooks/useQuote';
import { useIsComplete } from 'hooks/useIsComplete';

import { useBreakpointIndex } from '@theme-ui/match-media';

// import { getSectionData } from '../FormSection/helpers';
import { FormSection } from '../FormSection/FormSection';

export const FormSectionsMemoized = ({
    stage,
    index,
    setActiveStageIndex,
    isLastStage,
    dataComplete,
    onClose,
    onStageClick,
    ...props
}) => {
    const { quoteUuid } = useParams();
    const { data: quoteData } = useQuote({ quoteUuid });
    const breakpointIndex = useBreakpointIndex();
    const { isComplete } = useIsComplete(quoteData);

    const renderButton = () => {
        if (breakpointIndex > 2) {
            if (isLastStage && !isComplete) {
                return (
                    <Box
                        sx={{
                            mt: 3,
                            py: 2,
                            position: 'relative',
                            zIndex: 10,
                            top: -1,
                            px: [4, null, null, 0],
                        }}
                    >
                        <Button
                            type="button"
                            sx={{ width: 'full' }}
                            onClick={() => onStageClick({ index: false })}
                        >
                            Back to steps
                        </Button>
                    </Box>
                );
            }

            return (
                <Box
                    sx={{
                        mt: 3,
                        py: 2,
                        position: 'relative',
                        zIndex: 10,
                        top: -1,
                        px: [4, null, null, 0],
                    }}
                >
                    <Button
                        type="button"
                        sx={{ width: 'full' }}
                        onClick={() => onStageClick({ index: index + 1 })}
                    >
                        {isLastStage ? 'Submit Application' : 'Continue to Next Step'}
                    </Button>
                </Box>
            );
        }
    };

    return (
        <Box
            sx={{
                pb: ['100px', null, null, null],
            }}
        >
            <Text
                as="p"
                variant="body"
                sx={{
                    fontSize: ['12px', '14px'],
                    px: [30, null, null, 0],
                    mb: '22px',
                    color: 'primary',
                }}
            >
                Fields marked with * are required
            </Text>

            {stage.sections.map((section, index) => {
                return (
                    <FormSection
                        key={index}
                        index={index}
                        section={section}
                        isFirst={index === 0}
                        isLast={stage.sections.length === index + 1}
                        quoteData={quoteData}
                        sectionIndex={index}
                    />
                );
            })}

            {renderButton()}
        </Box>
    );
};

export const FormSections = memo(FormSectionsMemoized);
