export const QuoteStatus = {
    onboarded: 0,
    in_progress: 1,
    data_completed: 2,
    accepted: 3,
    dealer_quotes: 4,
    dealer_quote_accepted: 5,
    archived: 999,
};

export const DealerLinkLimits = {
    expiry: '1 month',
    max_dealer_quotes_per_quote: 3,
};
