import { Date } from 'components/FormInputs/Date';
import { RadioGroup } from 'components/FormInputs/RadioGroup';
import { Repeater } from 'components/FormInputs/Repeater';
import { Select } from 'components/FormInputs/Select';
import { Range } from 'components/FormInputs/Range';
import { TextInput } from 'components/FormInputs/TextInput';
import { Checkbox } from 'components/FormInputs/Checkbox';
import { Country } from 'components/FormInputs/Country';
import { Financier } from 'components/FormInputs/Financier';
import { Total } from 'components/Common/Total';
import { Signature } from 'components/FormInputs/Signature';
import { FileUpload } from 'components/FormInputs/FileUpload';
import { Number } from 'components/FormInputs/Number';
import { State } from 'components/FormInputs/State';

export const FormField = ({ type, fieldProps, sectionId }) => {
    const renderField = (type) => {
        return {
            text: <TextInput {...fieldProps} />,
            email: <TextInput {...fieldProps} />,
            number: <Number {...fieldProps} />,
            select: <Select {...fieldProps} />,
            date: <Date {...fieldProps} />,
            repeater: <Repeater {...fieldProps} repeater={true} />,
            checkbox: <Checkbox {...fieldProps} />,
            country: <Country {...fieldProps} />,
            financier: <Financier {...fieldProps} />,
            range: <Range {...fieldProps} />,
            total: <Total {...fieldProps} />,
            signature: <Signature {...fieldProps} />,
            file: <FileUpload {...fieldProps} />,
            textarea: <TextInput {...fieldProps} as="textarea" />,
            state: <State {...fieldProps} />,
            boolean: (
                <RadioGroup
                    options={[
                        { label: 'Yes', value: true },
                        { label: 'No', value: false },
                    ]}
                    {...fieldProps}
                />
            ),
        }[type];
    };

    return <>{renderField(type)}</>;
};
