import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

import api from 'config/api';

export const useApproveDealerQuote = () => {
    const approveQuote = useMutation(async (quoteId) => {
        try {
            let response = await api.get(`/dealerquote/accept/${quoteId}`);
            return response.data;
        } catch (e) {
            toast.error(e.response?.data?.message, {
                toastId: 'dealerapprove-error'
            });
            return null;
        }
    });

    return approveQuote;
};
