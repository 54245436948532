import { forwardRef } from 'react';
import { Flex, Text } from '@theme-ui/components';
import { motion } from 'framer-motion';
import { useBreakpointIndex } from '@theme-ui/match-media';

import { Icon } from 'components/Common/Icon';
import { ProgressCircle } from '../ProgressCircle';

const TextMotion = motion(Text);

export const StageHeader = forwardRef(
    ({ index, stage, sx, modalView, completeCount, ...props }, ref) => {
        const breakpointIndex = useBreakpointIndex();

        return (
            <Flex
                layout="position"
                sx={{
                    alignItems: 'center',
                    flexWrap: 'nowrap',
                    width: '100%',
                    px: modalView ? [4, null, 0] : 4,
                    pt: modalView ? [4, null, 0] : 4,
                    pb: [3, modalView ? 5 : 4],
                    ...sx,
                }}
                {...props}
            >
                <ProgressCircle
                    text={index}
                    percentage={completeCount}
                    large={modalView}
                />

                <TextMotion as="p" variant="lead" pl={3}>
                    {stage.stage}
                </TextMotion>

                {breakpointIndex > 0 && !modalView && (
                    <Icon icon="arrow-right" className="arrow" ml="auto" />
                )}
            </Flex>
        );
    }
);
