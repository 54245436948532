import api from 'config/api';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';

const fetchDealerQuoteData = async (accessToken) => {
    try {
        const response = await api.get(`/dealerquote/${accessToken}`);
        return response?.data?.data;
    } catch (e) {
        toast.error(e.response?.data?.message ?? 'Unable to find quote', {
            toastId: 'dealerquote-error'
        });
    }
    return null;
};

export const useDealerQuote = (accessToken, args = {}) => {
    return useQuery('dealerQuote', () => fetchDealerQuoteData(accessToken, args));
};
