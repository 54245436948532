import axios from 'axios';

axios.defaults.withCredentials = true;

const api = axios.create({
    withCredentials: true,
    baseURL: process.env.REACT_APP_API_URL,
});

// 401 (unauthenticated) response interceptor
api.interceptors.response.use(
    (response) => {
        return response;
    },

    (error) => {
        if (error.response !== undefined && 401 === error.response.status) {
            // redirect to home
        } else {
            return Promise.reject(error);
        }
    }
);

export default api;
