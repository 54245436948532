import { Text } from '@theme-ui/components';

import { Card } from '../Card';
import { Icon } from '../Icon';

export const Feature = ({ children, icon, heading, subheading, visible, ...props }) => {
    visible = visible === undefined ? true : visible;

    return ( visible &&
        <Card
            sx={{ display: 'inline-flex', flexDirection: 'column', alignItems: 'center' }}
        >
            {icon && <Icon icon={icon} mb={2} size="50px" />}

            {heading && (
                <Text as="p" sx={{ fontSize: '19px', textAlign: 'center' }}>
                    {heading}
                </Text>
            )}

            {subheading && (
                <Text as="p" sx={{ fontSize: 0, fontWeight: 600, textAlign: 'center' }}>
                    {subheading}
                </Text>
            )}
        </Card>
    );
};
