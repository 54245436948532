import numbro from 'numbro';

export const thousandSeparated = (value) => {
    return numbro(value).format({
        thousandSeparated: true,
        mantissa: 0,
    });
};

export const ensureNegative = (value) => {
    return -parseFloat(Math.abs(value));
};
