import styled from '@emotion/styled';
import { theme } from 'theme';

export const DateWrap = styled.div`
    position: relative;

    label {
        position: absolute;
        top: 8px;
        left: 15px;
        z-index: 2;
        font-weight: 600;
    }

    .SingleDatePicker {
        width: 100%;
        display: block;

        &_picker {
            z-index: 5;
        }

        .SingleDatePickerInput {
            width: 100%;
            display: inline-flex;
            border-radius: 4px;
            border: 1px solid ${(props) => (props.invalid ? '#EF404A' : (props.focused ? theme.colors.primary : '#d5d5e5'))};
        }

        .DateInput {
            width: 100%;
            background: transparent;

            &_input {
                font-size: 14px;
                color: #6b7788;
                padding: 21px 11px 5px 14px;
                border: none;
                background: transparent;

                &__focused {
                    border: none;
                }
            }
        }

        .CalendarDay__selected,
        .CalendarDay__selected:active,
        .CalendarDay__selected:hover {
            background: ${theme.colors.primary};
            border: 1px double ${theme.colors.primary};
            color: white;
        }
    }

    ${
        '' /* position: relative;
    color: ${(props) => props.theme.colors.green};
    width: ${(props) => (props.width ? props.width : '100%')};
    display: ${(props) => (props.display ? props.display : 'block')};
    margin-right: ${(props) => (props.marginRight ? props.marginRight : 0)};

    .Date_input {
        background: ${(props) => (props.bgColor ? theme.colors[props.bgColor] : 'white')};
        border: ${(props) => (props.noBorder ? 'none' : '2px solid #008540')};
        color: ${(props) => (props.fontColor ? theme.colors[props.fontColor] : 'black')};
        font-weight: 500;
        padding: ${(props) => (props.padding ? props.padding : '1.429rem 1.5rem')};
        height: ${(props) => (props.height ? props.height : '60px')};

        &::placeholder {
            color: ${(props) =>
                props.fontColor ? theme.colors[props.fontColor] : 'black'};
        }

        &__disabled {
            font-style: normal;
            color: lightgray;
            cursor: not-allowed;
        }
    }

    > svg {
        position: absolute;
        right: ${(props) => props.theme.space.xs};
        top: 50%;
        transform: translateY(-50%);
    }

    .calendar-navigation {
        margin-top: -0.23rem;

        &__month,
        &__year {
            font-size: 1.2rem;
            padding: 0.3rem;
            border: 1px solid #e4e7e7;
            border-radius: 3px;
            margin: 0 0.2rem;
        }
    }

    .DayPickerNavigation_button__horizontalDefault {
        top: 19px;
    }

    .DayPickerNavigation_leftButton__horizontalDefault {
        left: 12px;
    }

    .DayPickerNavigation_rightButton__horizontalDefault {
        right: 12px;
    } */
    }
`;
