import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { grabFirstError } from 'utils/helpers';

import api from 'config/api';

export const useSendDealerLink = () => {
    const sendDealerLink = useMutation(async (token) => {
        try {
            let response = await api.post(`/dealerquote/send-to-dealer`, {
                token: token,
            });
            return response.data;
        } catch (e) {
            const first_error = grabFirstError(e.response?.data?.errors);
            toast.error(first_error ?? e.response?.data?.message, {
                toastId: 'dealerquote-send-error',
            });
            return null;
        }
    });

    return sendDealerLink;
};
