import { Box, Text } from '@theme-ui/components';
import { Icon } from 'components/Common/Icon';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import { theme } from 'theme';

export const ProgressCircle = ({ text, percentage, large, ...props }) => {
    return (
        <Box sx={{ width: large ? 75 : 50, flexShrink: 0, position: 'relative' }}>
            <CircularProgressbarWithChildren
                value={percentage}
                strokeWidth={6}
                styles={buildStyles({
                    strokeLinecap: 'butt',
                    pathTransitionDuration: 0.25,
                    textColor: percentage === 100 ? theme.colors.secondary : '#9CADB8',
                    trailColor: theme.colors.grayLight,
                    pathColor: theme.colors.secondary,
                })}
            >
                <Text
                    as="span"
                    sx={{ fontSize: ['16px', '26px'] }}
                    color={percentage === 100 ? 'secondary' : 'grayDark'}
                >
                    {text + 1}
                </Text>
            </CircularProgressbarWithChildren>

            {percentage === 100 && (
                <Icon
                    icon="tick-blue"
                    color="secondary"
                    sx={{ position: 'absolute', top: '-7px', right: '-6px' }}
                    size={large ? '35px' : '25px'}
                />
            )}
        </Box>
    );
};

ProgressCircle.defaultProps = {
    color: 'grayLight',
};
