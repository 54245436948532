import api from 'config/api';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';

const getFinanciers = async () => {
    try {
        const response = await api.get('/financiers');
        return response.data.data;
    } catch (e) {
        toast.error(e?.response?.data?.message, {
            toastId: 'get-financiers-error',
        });
        return e;
    }
};

export const useFinanciers = (args = {}) => {
    return useQuery('financiers', getFinanciers, args);
};
