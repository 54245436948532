import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { grabFirstError } from 'utils/helpers';

import api from 'config/api';

export const useDealerLink = () => {
    const dealerLink = useMutation(async (values) => {
        try {
            let response = await api.post(`/dealerquote/share`, values);
            return response.data;
        } catch (e) {
            const first_error = grabFirstError(e.response?.data?.errors);
            toast.error(first_error ?? e.response?.data?.message, {
                toastId: 'dealerquote-share-error'
            });
            return null;
        }
    });

    return dealerLink;
};
