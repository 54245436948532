/** @jsxImportSource theme-ui */
import { Box, Grid, Text } from '@theme-ui/components';
import { cloneElement } from 'react';
import { Button } from '../Button';

import { ContentBox } from '../ContentBox';

export const FeatureSummary = ({ items, buttonUrl, ...props }) => {
    if (!items.length) {
        return '';
    }

    return (
        <ContentBox
            sx={{
                py: [4, 5],
                px: [3, 6],
            }}
            {...props}
        >
            <Text as="h2" variant="intro" sx={{ textAlign: 'center', mb: 4 }}>
                What's included?
            </Text>

            <Grid gap={3} columns={[1, 2, '', 4]} mb={4}>
                {items.map((item, index) => cloneElement(item, { key: index }, null))}
            </Grid>

            {buttonUrl && (
                <Box sx={{ textAlign: 'center' }}>
                    <Button
                        variant="bordered"
                        size="sm"
                        as="a"
                        href={buttonUrl}
                        target="_blank"
                    >
                        View Full Quote PDF
                    </Button>
                </Box>
            )}
        </ContentBox>
    );
};

FeatureSummary.defaultProps = {
    items: [],
    buttonUrl: false,
};
