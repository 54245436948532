import { useEffect, useState } from 'react';
import { QuoteStatus } from 'utils/constants'

export const useIsComplete = (quoteData) => {
    const [isComplete, setIsComplete] = useState(false);

    useEffect(() => {
        if (quoteData.quote_status_id === QuoteStatus.data_completed) {
            setIsComplete(true);
        } else {
            setIsComplete(false);
        }
    }, [quoteData]);

    return { isComplete };
};
