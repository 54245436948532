import { Box, Text } from '@theme-ui/components';
import { ContentBox } from 'components/Common/ContentBox';
import { Icon } from 'components/Common/Icon';
import { AnimatePresence, motion } from 'framer-motion';
import { useValidateStages } from 'pages/Dashboard/hooks/useValidateStages';
import { useRef } from 'react';
import useOnClickOutside from 'use-onclickoutside';

import { FormSections } from '../FormSections';
import { SubmitStage } from '../SubmitStage';
import { StageHeaderStyled } from './styled';

const BoxMotion = motion(Box);

export const StageDesktop = ({
    stage,
    sx,
    index,
    isFirst,
    isLastStage,
    isSubmission,
    isSelected,
    onStageClick,
    onClose,
    setActiveStageIndex,
    dataComplete,
    quoteData,
    ...props
}) => {
    const ref = useRef(null);
    useOnClickOutside(ref, () => setActiveStageIndex(false));
    const completeCount = useValidateStages(stage, quoteData);

    return (
        <Box>
            {!stage.submission && (
                <Box
                    key={index}
                    sx={{
                        cursor: 'pointer',
                        width: '100%',
                        ...sx,
                    }}
                    {...props}
                >
                    <StageHeaderStyled
                        tabIndex="0"
                        index={index}
                        stage={stage}
                        key={index}
                        onKeyPress={(e) => {
                            if(e.key === 'Enter'){
                                onStageClick({ stage, index })
                            }
                        }}
                        onClick={() => onStageClick({ stage, index })}
                        completeCount={completeCount}
                    />
                </Box>
            )}

            <AnimatePresence initial={false}>
                {isSelected && (
                    <>
                        {/* bg overlay */}
                        <BoxMotion
                            key="overlay"
                            initial="closed"
                            animate="open"
                            exit="closed"
                            variants={{
                                open: {
                                    opacity: 0.9,
                                    pointerEvents: 'auto',
                                },
                                closed: { opacity: 0, pointerEvents: 'none' },
                            }}
                            transition={{
                                duration: 0.25,
                                ease: [0.04, 0.62, 0.23, 0.98],
                            }}
                            sx={{
                                position: 'fixed',
                                top: 0,
                                left: 0,
                                width: 'full',
                                height: 'full',
                                bg: 'primary',
                                zIndex: 40,
                                cursor: 'pointer',
                            }}
                        />

                        {/* content modal */}
                        <Box
                            sx={{
                                minHeight: '100vh',
                                width: 'full',
                                overflowY: 'scroll',
                                position: 'fixed',
                                zIndex: 100,
                                top: 0,
                                left: 0,
                            }}
                        >
                            <BoxMotion
                                key="content"
                                initial="closed"
                                animate="open"
                                exit="closed"
                                ref={ref}
                                variants={{
                                    open: {
                                        opacity: 1,
                                        pointerEvents: 'auto',
                                        y: 0,
                                        x: '-50%',
                                    },
                                    closed: {
                                        opacity: 0,
                                        pointerEvents: 'none',
                                        y: -10,
                                        x: '-50%',
                                    },
                                }}
                                transition={{
                                    duration: 0.3,
                                }}
                                sx={{
                                    width: '100%',
                                    maxWidth: 870,
                                    position: 'absolute',
                                    left: '50%',
                                    top: 7,
                                    height: 'auto',
                                    pb: 7,
                                }}
                            >
                                <Text
                                    as="button"
                                    variant="button"
                                    onClick={onClose}
                                    sx={{
                                        bg: 'transparent',
                                        color: 'white',
                                        border: 'none',
                                        mb: 3,
                                        cursor: 'pointer',
                                        transition: 'all 0.5s ease',
                                        display: 'inline-flex',
                                        '&:hover': {
                                            color: 'secondary',
                                        },
                                        '&:focus': {
                                            outlineColor: 'white'
                                        }
                                    }}
                                >
                                    <Icon icon="arrow-left" pr={3} />
                                    Back
                                </Text>

                                <ContentBox sx={{ p: 6 }}>
                                    {!isSubmission ? (
                                        <>
                                            <StageHeaderStyled
                                                index={index}
                                                stage={stage}
                                                key={index}
                                                modalView
                                                completeCount={completeCount}
                                            />

                                            <FormSections
                                                stage={stage}
                                                setActiveStageIndex={setActiveStageIndex}
                                                isLastStage={isLastStage}
                                                isSubmission={isSubmission}
                                                index={index}
                                                dataComplete={dataComplete}
                                                onStageClick={onStageClick}
                                            />
                                        </>
                                    ) : (
                                        <SubmitStage onClose={onClose} />
                                    )}
                                </ContentBox>
                            </BoxMotion>
                        </Box>
                    </>
                )}
            </AnimatePresence>
        </Box>
    );
};
