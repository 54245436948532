import { Box, Text } from '@theme-ui/components';

export const DigitBox = ({ digit, children, ...props }) => {
    return (
        <Box sx={{ borderRadius: 4, p: 3, px: [1, 3], bg: 'white', color: 'primary', mx: 1, textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', minWidth: ['45px', '80px'] }}>
            <Text as="span" variant="headline">
                {digit}
            </Text>
        </Box>
    );
};
