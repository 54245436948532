import { Box } from '@theme-ui/components';

export const Container = ({ children, sx, ...props }) => (
    <Box
        px={3}
        mx="auto"
        sx={{ width: '100%', maxWidth: ['100%', '', '', '910px'], ...sx }}
        {...props}
    >
        {children}
    </Box>
);
