import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

import api from 'config/api';

export const useUploadSignature = () => {
    return useMutation(async (values) => {
        try {
            let response = await api.post(`/signature/upload`, values);
            if (response.data.success) {
                return response.data;
            }
        } catch (e) {
            toast.error(e.response?.data?.message, {
                toastId: 'upload-signature-error'
            });
        }
    });
};
