import styled from '@emotion/styled';
import { Box } from '@theme-ui/components';

import { theme } from 'theme';

export const RadioGroupStyled = styled(Box)`
    display: flex;
    align-items: center;

    input[type='radio'] {
        clip: rect(0 0 0 0);
        clip-path: inset(100%);
        height: 1px;
        overflow: hidden;
        position: absolute;
        white-space: nowrap;
        width: 1px;

        &:checked + span {
            background-color: ${theme.colors.primary};
            z-index: 1;
            color: white;
        }
        &:focus + span{
            background-color: ${theme.colors.primary};
            z-index: 1;
            color: white;
        }
    }

    label {
        span {
            border: 2px solid ${theme.colors.primary};
            display: block;
            cursor: pointer;
            background-color: transparent;
            position: relative;
            color: ${theme.colors.primary};
            text-align: center;
            transition: background-color 0.5s ease;
            margin-right: 7px;
        }

        &:hover{
            span {
                background-color: ${theme.colors.grayLight};
            }
        }
    }
`;
