import _set from 'lodash.set';

import { getFieldName, getDefaultValue } from '../FormSections/helpers';

// recursive reducer to calculate total number of fields within a section
export const getGroupData = (group, quoteData) => {
    let fieldsReduce;

    let validationSchema = group.validationSchema;

    fieldsReduce = (acc, item) => {
        // if (item.groupFieldName) {
        //     groupFieldName = item.groupFieldName;
        // } else {
        //     // groupFieldName = '';
        // }

        if (item.fields && Array.isArray(item.fields) && item?.type !== 'repeater') {
            return item.fields.reduce(fieldsReduce, acc);
        }

        if (Array.isArray(item)) {
            return item.reduce(fieldsReduce, acc);
        }

        acc.count = acc.count + 1;

        const fieldName = getFieldName(item, group.groupFieldName);

        // set default value
        if (item.type !== 'total') {
            // treat repeaters differently - need to create object structures for Formik validation to work properly
            // if (item.type === 'repeater') {
            _set(
                acc.initialValues,
                fieldName,
                getDefaultValue(item, fieldName, quoteData)
            );
        }

        // if has value, count as complete
        acc.countCompleted = getDefaultValue(item, fieldName, quoteData)
            ? acc.countCompleted + 1
            : acc.countCompleted;

        acc.fields.push(item);
        return acc;
    };

    return group.fields.reduce(fieldsReduce, {
        fields: [],
        initialValues: {},
        validationSchema: validationSchema,
    });
};
