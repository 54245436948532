import { Label as LabelUi } from '@theme-ui/components';

export const Label = ({ text, name, sx, ...props }) => {
    return (
        <LabelUi
            as="label"
            htmlFor={name}
            display="block"
            sx={{
                fontWeight: 600,
                fontSize: 10,
                pointerEvents: 'none',
                ...sx,
            }}
            {...props}
            mb={2}
            dangerouslySetInnerHTML={{ __html: text }}
        />
    );
};

Label.defaultProps = {
    name: '',
};
