import { forwardRef } from 'react';
import { Box } from '@theme-ui/components';
import { useField } from 'formik';
import NumberFormat from 'react-number-format';

import { useDebounce } from 'hooks/useDebounce';
import { Error } from '../Error';
import { TextInput } from '../TextInput';

const customInput = ({ onChange, customValueChange, ...inputProps }) => {
    return <TextInput {...inputProps} onChangeOverride={onChange} />;
};

export const Number = forwardRef(({ onChange, apiName, allowNegative, fallback, ...props }, ref) => {
    // eslint-disable-next-line
    const [field, meta, { setValue, setTouched }] = useField(props.name);
    const debouncedSave = useDebounce((nextValue) => onChange(nextValue), 750);

    return (
        <Box sx={{ position: 'relative' }}>
            <Error
                text={meta.error}
                isVisible={meta.touched && meta.error}
                sx={{
                    position: 'absolute',
                    right: '13px',
                    top: '7px',
                }}
            />

            <NumberFormat
                {...props}
                thousandSeparator={true}
                allowNegative={allowNegative === true}
                isNumericString={false}
                onValueChange={(val) => {
                    setValue(val.floatValue);
                    if (onChange) {
                      debouncedSave({ name: apiName, value: val.floatValue !== undefined ? val.floatValue : null });
                    }
                }}
                onBlur={() => {
                    setTouched(true);
                }}
                customInput={customInput}
                value={field.value ? field.value : (fallback ?? null)}
            />
        </Box>
    );
});
