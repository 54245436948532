import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { QuoteStatus } from 'utils/constants'

import api from 'config/api';

export const useApproveQuote = () => {
    const queryClient = useQueryClient();

    return useMutation(
        async (quoteUuid) => {
            try {
                let response = await api.post(`/approve/${quoteUuid}`);
                return response.data;
            } catch (e) {
                toast.error(e.response?.data?.message, {
                    toastId: 'approve-quote-error',
                });
                return e.response?.data;
            }
        },
        {
            onSuccess: (data, variables) => {
                if (data.success) {
                    queryClient.setQueryData('quote', (oldData) => ({
                        ...oldData,
                        // this ID should be returned from the server and accessed from 'variables' above
                        quote_status_id: QuoteStatus.accepted,
                        exports: data.data?.exports,
                    }));
                }
            },
        }
    );
};
