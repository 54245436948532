import React from 'react';
import { useField } from 'formik';
import { Box, Flex, Slider } from 'theme-ui';

import { Label } from '../Label';
import { Error } from '../Error';
import { Icon } from 'components/Common/Icon';
import { useDebounce } from 'hooks/useDebounce';
import { thousandSeparated } from 'utils/formatting';

// forward ref so we can focus with parent components
export const Range = ({
    label,
    name,
    min = 200,
    max = 5000,
    step = 50,
    apiName,
    type,
    className,
    placeholder,
    validIndicator,
    validationError,
    showLabel,
    onChangeOverride,
    onChange,
    validation,
    groupSx,
    ...props
}) => {
    const [field, meta, { setValue }] = useField(name);
    const debouncedSave = useDebounce((nextValue) => onChange(nextValue), 750);

    const fieldProps = { ...field, ...props };
    const progress = parseFloat(meta.value) > parseFloat(min) ? (parseFloat(meta.value) / parseFloat(max)) * 100 : 0;
    const currentValue = meta.value ?? min;

    return (
        <Box sx={{ mb: 3, position: 'relative', ...groupSx }}>
            {label !== false && showLabel && (
                <>
                    <Label
                        sx={{ position: 'absolute', left: 15, top: '7px' }}
                        variant="label"
                        name={field.name}
                        text={
                            label
                                ? `${label}${props.required ? '*' : ''}`
                                : placeholder
                        }
                    />

                    {validationError && (
                        <Error
                            text={meta.error}
                            isVisible={meta.touched && meta.error}
                            sx={{
                                position: 'absolute',
                                right: '13px',
                                top: '7px',
                            }}
                        />
                    )}
                </>
            )}


            <Flex sx={{ width: '100%' }}>
                <Box color="grayDark" sx={{ fontSize: 0 }}>${thousandSeparated(min)}</Box>
                <Box color="grayDark" sx={{ fontSize: 0 }} ml={'auto'}>${thousandSeparated(max)}</Box>
            </Flex>
            <Slider
                {...fieldProps}
                {...props}
                type="range"
                fontSize={0}
                sx={{
                    color: 'grayDark',
                    background: `linear-gradient(to right, #282781 calc(${progress}% - 35px), #fff ${progress}%)`
                }}
                value={field.value ?? currentValue}
                min={min}
                max={max}
                step={step}
                invalid={meta.touched && meta.error}
                onChange={(event) => {
                    const { value } = event.target;
                    if (onChangeOverride) {
                        onChangeOverride(event);
                    } else {
                        setValue(value);

                        onChange &&
                            debouncedSave({
                                name: apiName,
                                value: value === null ? '' : value,
                            });
                    }
                }}
            />
            <Box color="primary" sx={{ fontWeight: 'bold' }}>
                ${thousandSeparated(currentValue)}
            </Box>

            {(fieldProps.readOnly || validIndicator === true) &&
                meta.value !== '' &&
                meta.error === undefined && (
                    <Icon
                        icon={fieldProps.readOnly ? "lock" : "tick"}
                        sx={{
                            position: 'absolute',
                            right: '14px',
                            top: '50%',
                            transform: 'translateY(-50%)',
                        }}
                    />
                )}
        </Box>
    );
};
