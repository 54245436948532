/** @jsxImportSource theme-ui */
import { useContext, memo } from 'react';
import { Box, Flex } from '@theme-ui/components';
import { Themed } from '@theme-ui/mdx';
import { AnimatePresence, motion } from 'framer-motion';
import { useToggle } from 'hooks/useToggle';
import { Icon } from 'components/Common/Icon';
import { FormGroup } from '../FormGroup';
import { SectionProgress } from 'components/Common/SectionProgress';
import { AppContext } from 'context/AppContext';
import { checkCondition } from '../FormSections/helpers';

const BoxMotion = motion(Box);

export const FormSectionMemoized = memo(
    ({ section, isFirst, isLast, sx, sectionIndex, quoteData, ...props }) => {
        const [isOpen, setIsOpen] = useToggle();
        const { dispatch } = useContext(AppContext);

        const getTotalGroups = (acc, group) => {
            if (group.condition?.field || group.condition?.fields) {
                if (!checkCondition(group.condition, quoteData)) {
                    return acc;
                }
            }

            return (acc = acc + 1);
        };

        const countValidGroups = (acc, group) => {
            if (group.condition?.field || group.condition?.fields) {
                if (!checkCondition(group.condition, quoteData)) {
                    return acc;
                }
            }

            if (group.isValid) {
                return (acc = acc + 1);
            }

            return acc;
        };

        let validGroups = section.groups.reduce(countValidGroups, 0);
        let totalGroups = section.groups.reduce(getTotalGroups, 0);

        return (
            <Box
                sx={{
                    borderBottom: !isLast ? '1px solid' : 'none',
                    borderColor: 'grayLight',
                    position: 'relative',
                    bg: 'white',
                    // zIndex: index,
                }}
            >
                <Flex
                    tabIndex="0"
                    onKeyPress={(e) => {
                        if(e.key === 'Enter'){
                            setIsOpen(!isOpen);
                            dispatch({
                                type: 'SET_ACTIVE',
                                payload: { section: sectionIndex },
                            });
                        }
                    }}
                    onClick={() => {
                        setIsOpen(!isOpen);
                        dispatch({
                            type: 'SET_ACTIVE',
                            payload: { section: sectionIndex },
                        });
                    }}
                    sx={{
                        justifyContent: 'space-between',
                        px: [4, null, null, 0],
                        pb: 4,
                        pt: isFirst ? 0 : 4,
                        cursor: 'pointer',
                        transition: 'easeDefault',
                        transitionProperty: 'color',
                        '&:hover': {
                            color: 'secondary',
                        },
                        '&:focus': {
                            color: 'secondary',
                            outline: 'none'
                        }
                    }}
                >
                    <Themed.h5 sx={{ margin: 0, color: 'inherit' }}>
                        {section.title}
                    </Themed.h5>

                    <Flex sx={{ alignItems: 'center' }}>
                        <SectionProgress
                            totalCount={totalGroups}
                            completedCount={validGroups}
                            complete={validGroups === totalGroups}
                            sx={{ top: 3 }}
                        />

                        <Icon
                            icon="arrow-right-light"
                            sx={{
                                transition: 'easeDefault',
                                transitionProperty: 'transform',
                                transform: isOpen ? 'rotate(45deg)' : 'none',
                            }}
                        />
                    </Flex>
                </Flex>

                <AnimatePresence initial={false}>
                    <BoxMotion
                        key={sectionIndex}
                        initial={isOpen ? 'open' : 'collapsed'}
                        animate={isOpen ? 'open' : 'collapsed'}
                        exit="collapsed"
                        variants={{
                            open: {
                                opacity: 1,
                                height: 'auto',
                                transitionEnd: {
                                    overflow: 'visible',
                                },
                            },
                            collapsed: {
                                opacity: 0,
                                height: 0,
                                overflow: 'hidden',
                                transitionEnd: {
                                    overflow: 'hidden',
                                },
                            },
                        }}
                        transition={{
                            duration: 0.5,
                        }}
                        sx={{
                            bg: 'white',
                            pointerEvents: isOpen ? 'auto' : 'none',
                            zIndex: isOpen ? -1 : 2,
                            overflow: 'hidden',
                        }}
                    >
                        <Box sx={{ px: [15, null, null, 0], pb: 4 }}>
                            {section.groups &&
                                section.groups.map((group, index) => (
                                    <FormGroup
                                        key={`${sectionIndex}_${index}`}
                                        sectionIndex={sectionIndex}
                                        groupIndex={index}
                                        group={group}
                                        quoteData={quoteData}
                                        isOpen={isOpen}
                                    />
                                ))}
                        </Box>
                    </BoxMotion>
                </AnimatePresence>
            </Box>
        );
    }
);

export const FormSection = FormSectionMemoized;
