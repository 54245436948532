import { Box, Text } from '@theme-ui/components';

import { Headline } from 'components/Common/Headline';

export const OnboardConfirmation = () => {
    return (
        <Box>
            <Headline sx={{ color: 'primary', mb: 3 }}>Thank You</Headline>
            <Text as="p" variant="lead" mb={0}>
                We have sent an access link to your email.
            </Text>
        </Box>
    );
};
