import React from 'react';
import { useField } from 'formik';
import { Box } from 'theme-ui';

import { Label } from '../Label';
import { InputStyled } from './styled';
import { Error } from '../Error';
import { Icon } from 'components/Common/Icon';
import { useDebounce } from 'hooks/useDebounce';

// forward ref so we can focus with parent components
export const TextInput = React.forwardRef(
    ({ label, name, apiName, type, className, placeholder, buttonText, onButtonClick, controlledInput, icon, validIndicator, validationError, showLabel, onChangeOverride, onChange, customValueChange, textType, validation, groupSx, ...props }, ref) => {
        const [field, meta, { setValue }] = useField(name);
        const debouncedSave = useDebounce((nextValue) => onChange(nextValue), 750);

        const fieldProps = { ...field, ...props };

        return (
            <Box sx={{ mb: 3, position: 'relative', ...groupSx }}>
                {label !== false && showLabel && (
                    <>
                        <Label sx={{ position: 'absolute', left: 15, top: '7px' }} variant="label" name={field.name} text={label ? `${label}${props.required ? '*' : ''}` : placeholder} />

                        {validationError && (
                            <Error
                                text={meta.error}
                                isVisible={meta.touched && meta.error}
                                sx={{
                                    position: 'absolute',
                                    right: '13px',
                                    top: '7px',
                                }}
                            />
                        )}
                    </>
                )}

                <InputStyled
                    {...fieldProps}
                    {...props}
                    type={textType || type}
                    // placeholder={`${placeholder}${required ? '*' : ''}`}
                    icon={icon}
                    fontSize={0}
                    buttonText={buttonText}
                    sx={{
                        color: 'grayDark',
                    }}
                    ref={ref}
                    placeholder={placeholder}
                    customValueChange={customValueChange}
                    invalid={meta.touched && meta.error}
                    onChange={(event) => {
                        const { value } = event.target;
                        if (onChangeOverride) {
                            onChangeOverride(event);
                        } else {
                            setValue(value);

                            onChange &&
                                debouncedSave({
                                    name: apiName,
                                    value: value === null ? '' : value,
                                });
                        }
                    }}
                />

                {(fieldProps.readOnly || validIndicator === true) && meta.value !== '' && meta.error === undefined && (
                    <Icon
                        icon={fieldProps.readOnly ? 'lock' : 'tick'}
                        sx={{
                            position: 'absolute',
                            right: '14px',
                            top: '50%',
                            transform: 'translateY(-50%)',
                        }}
                    />
                )}
            </Box>
        );
    }
);

TextInput.defaultProps = {
    validationError: true,
    placeholder: '',
    showLabel: true,
    controlledInput: true,
    type: 'text',
    validIndicator: true,
    required: false,
};
