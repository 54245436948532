import React from 'react';
import { useField } from 'formik';
import { Box } from 'theme-ui';

import { Label } from '../Label';
import { InputStyled } from './styled';
import { Error } from '../Error';
import { Icon } from 'components/Common/Icon';
import { useDebounce } from 'hooks/useDebounce';

// forward ref so we can focus with parent components
export const TextArea = React.forwardRef(
    (
        {
            label,
            name,
            apiName,
            type,
            className,
            placeholder,
            buttonText,
            onButtonClick,
            controlledInput,
            icon,
            validIndicator,
            validationError,
            showLabel,
            onChangeOverride,
            onChange,
            customValueChange,
            textType,
            validation,
            groupSx,
            ...props
        },
        ref
    ) => {
        const [field, meta, { setValue }] = useField(name);
        const debouncedSave = useDebounce((nextValue) => onChange(nextValue), 750);

        const fieldProps = { ...field, ...props };

        return (
            <Box sx={{ position: 'relative', mb: 3, ...groupSx }}>
                {label !== false && showLabel && (
                    <>
                        <Label
                            sx={{ position: 'absolute', left: 15, top: '7px' }}
                            variant="label"
                            name={field.name}
                            text={
                                label
                                    ? `${label}${props.required ? '*' : ''}`
                                    : placeholder
                            }
                        />

                        {validationError && (
                            <Error
                                text={meta.error}
                                isVisible={meta.touched && meta.error}
                                sx={{
                                    position: 'absolute',
                                    right: '13px',
                                    top: '7px',
                                }}
                            />
                        )}
                    </>
                )}

                <InputStyled
                    {...fieldProps}
                    {...props}
                    icon={icon}
                    fontSize={0}
                    buttonText={buttonText}
                    sx={{
                      color: 'grayDark',
                      ...props.sx
                    }}
                    ref={ref}
                    customValueChange={customValueChange}
                    invalid={meta.touched && meta.error}
                    onChange={(event) => {
                        const { value } = event.target;
                        if (onChangeOverride) {
                            onChangeOverride(event);
                        } else {
                            setValue(value);

                            onChange &&
                                debouncedSave({
                                    name: apiName,
                                    value: value === null ? '' : value,
                                });
                        }
                    }}
                />

                {validIndicator === true &&
                    meta.value !== '' &&
                    meta.error === undefined && (
                        <Icon
                            icon={fieldProps.readOnly ? "lock" : "tick"}
                            sx={{
                                position: 'absolute',
                                right: '14px',
                                top: '50%',
                                transform: 'translateY(-50%)',
                            }}
                        />
                    )}
            </Box>
        );
    }
);

TextArea.defaultProps = {
    validationError: true,
    placeholder: '',
    showLabel: true,
    controlledInput: true,
    validIndicator: true,
    required: false,
    groupSx: {},
};
