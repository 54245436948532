/** @jsxImportSource theme-ui */
import { useState } from 'react';
import { Switch, Route, useParams, Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Text, Image, Box, Flex } from '@theme-ui/components';
import { Themed } from '@theme-ui/mdx';
import { useQuote } from 'pages/Acceptance/hooks/useQuote';
import { LoadingScreen } from 'components/Common/LoadingScreen';
import { Icon } from 'components/Common/Icon';
import { QuoteForm } from './components/QuoteForm';
import { Container } from 'components/Common/Container';
import { Headline } from 'components/Common/Headline';
import { ContentBox } from 'components/Common/ContentBox';
import { FixedContentBox } from 'components/Common/FixedContentBox';
import { QuoteStatus } from 'utils/constants';
import { motion } from 'framer-motion';
import { fadeIn } from 'utils/animations';

const BoxMotion = ({ children, ...props }) => {
    return (
        <motion.div {...fadeIn} {...props}>
            {children}
        </motion.div>
    );
};

export const DealerQuotes = ({ ...props }) => {
    const [activeDealer, setActiveDealer] = useState(false);
    const { quoteUuid } = useParams();
    const [isApproved, setApproved] = useState(false);

    const { isLoading, isError, data: quoteData, error } = useQuote({
        quoteUuid,
        getToken: true,
    });

    if (isLoading) {
        return <LoadingScreen />;
    }

    if (isError) {
        console.log(error);
        toast.error(error.message, {
            toastId: 'quote-load-error',
        });
        return <Redirect to="/" />;
    }

    const { personal, vehicle, dealer_quotes, quote } = quoteData;
    const dealer_count = dealer_quotes !== null ? dealer_quotes.length : 0;
    const agentName = quote?.quoted_by?.name?.split(' ')?.[0];

    // if quote is in-progress
    const renderComponent = () => {
        if (isApproved) {
            return (
                <Container
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        minHeight: 'calc(100vh - 250px)',
                    }}
                >
                    <Icon icon="tick-large" />
                    <Headline sx={{ color: 'white', mt: 3, mb: 2 }}>{`Thanks${agentName ? ` ${agentName}` : ''}!`}</Headline>
                    <Text
                        as="span"
                        sx={{
                            display: 'block',
                            color: 'white',
                            fontSize: 3,
                            mt: 3,
                            textAlign: 'center',
                            fontWeight: 600,
                        }}
                    >
                        Your acceptance has been submitted.
                        <br />You will receive a confirmation email.
                    </Text>
                </Container>
            );
        }

        switch (quoteData?.quote_status_id) {
            case QuoteStatus.dealer_quotes:
                return (
                    <Container>
                        <Headline sx={{ mb: 4, color: 'white' }}>
                            Hi {quoteData?.quote?.quoted_by.name},<br />
                            <Text
                                as="span"
                                sx={{
                                    display: 'block',
                                    color: 'white',
                                    fontSize: 5,
                                    lineHeight: '33px',
                                    mt: 3,
                                }}
                            >
                                {dealer_count !== 1 ? `${dealer_count} dealers have` : 'a dealer has'} responded to quote #{quoteData?.quote_id} for {personal?.first_name} to request a {vehicle?.model_year} {vehicle?.make} {vehicle?.model}{' '}
                                {vehicle?.variant}
                            </Text>
                        </Headline>

                        {vehicle?.photo && (
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    p: '0 50px',
                                    mb: [0, 4],
                                }}
                            >
                                <Image
                                    src={vehicle.photo}
                                    sx={{
                                        borderRadius: '8px',
                                        overflow: 'hidden',
                                    }}
                                />
                            </Box>
                        )}

                        <FixedContentBox>
                            <Flex
                                sx={{
                                    pb: '16px',
                                    fontSize: '19px',
                                    fontWeight: 'bold',
                                }}
                            >
                                <Icon icon="pencil" sx={{ mr: '11px' }} />
                                {activeDealer ? 'Quote Review' : 'Quotes'}
                            </Flex>
                            {activeDealer && <QuoteForm dealer={activeDealer} quoteData={quoteData} handleBackToQuotes={() => setActiveDealer(null)} onApprove={() => setApproved(true)} />}
                            {!activeDealer &&
                                dealer_quotes.map((dealer, index) => (
                                    <Box
                                        key={index}
                                        sx={{
                                            position: 'relative',
                                            bg: 'white',
                                            pr: 2,
                                        }}
                                    >
                                        <Flex
                                            tabIndex="0"
                                            onClick={() => {
                                                setActiveDealer(dealer);
                                            }}
                                            sx={{
                                                justifyContent: 'space-between',
                                                pb: 4,
                                                pt: 4,
                                                cursor: 'pointer',
                                                transition: 'easeDefault',
                                                transitionProperty: 'color',
                                                '&:hover': {
                                                    color: 'secondary',
                                                },
                                                '&:focus': {
                                                    color: 'secondary',
                                                    outline: 'none',
                                                },
                                            }}
                                        >
                                            <Themed.h5 sx={{ margin: 0, color: 'inherit' }}>
                                                {dealer.dealership} [#{dealer.dealer_reference}]
                                            </Themed.h5>
                                            <Flex sx={{ alignItems: 'center' }}>
                                                <Icon
                                                    icon="arrow-right-light"
                                                    sx={{
                                                        transition: 'easeDefault',
                                                        transitionProperty: 'transform',
                                                        transform: 'rotate(0)',
                                                    }}
                                                />
                                            </Flex>
                                        </Flex>
                                    </Box>
                                ))}
                        </FixedContentBox>
                    </Container>
                );

            case QuoteStatus.dealer_quote_accepted:
                return (
                    <Container>
                        <ContentBox
                            sx={{
                                textAlign: 'center',
                                maxWidth: 660,
                                mx: 'auto',
                                p: [4, 6],
                                minHeight: '416px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <BoxMotion key="confirmation">
                                <Headline sx={{ color: 'primary', mb: 3 }}>Quote already sorted</Headline>
                                <Text as="p" variant="lead" mb={4}>
                                    Quote #{quoteData?.quote_id} already has a dealer quote that has been accepted.
                                </Text>
                            </BoxMotion>
                        </ContentBox>
                    </Container>
                );

            default:
                toast.info('Quote found and already in progress', {
                    toastId: 'quote-load-error',
                });
                return <Redirect to="/dashboard" />;
        }
    };

    return (
        <Switch>
            <Route path="/quote/:quoteUuid?/dealerquotes" exact>
                {renderComponent()}
            </Route>

            <Redirect to="/dashboard" />
        </Switch>
    );
};
