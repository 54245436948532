import { useFinanciers } from 'hooks/useFinanciers';
import { Select } from '../Select';

export const Financier = ({ ...props }) => {
    const { data: options } = useFinanciers({
        select: (data) => data.map((financier) => ({ label: financier.name, value: financier.finance_liability_financier_id })),
    });

    return <Select options={options} {...props}></Select>;
};
