import { useEffect, useState } from 'react';
import * as Yup from 'yup';

import { checkCondition, getInitialValues } from '../components/FormSections/helpers';

export const useValidateStages = (stage, quoteData) => {
    const [completeCount, setCompleteCount] = useState(0);

    useEffect(() => {
        let validGroupsCount = 0;

        if (stage.sections) {
            const { groups } = getInitialValues(stage, quoteData);

            // get total groups which are applicable
            const getTotalApplicableGroups = (acc, group) => {
                if (group.condition?.field || group.condition?.fields) {
                    if (!checkCondition(group.condition, quoteData)) {
                        return acc;
                    }
                }

                return (acc = acc + 1);
            };

            const totalApplicableGroups = groups.reduce(getTotalApplicableGroups, 0);

            // loop through each validation schema for groups, and check against the quote data stored
            // if valid groups count === total groups, then it's considered complete
            groups.forEach((group) => {
                if (group.condition?.field || group.condition?.fields) {
                    if (!checkCondition(group.condition, quoteData)) {
                        return validGroupsCount;
                    }
                }

                if (group.validationSchema !== undefined) {
                    try {
                        Yup.object(group.validationSchema).validateSync(quoteData);
                        return (validGroupsCount = validGroupsCount + 1);
                    } catch (e) {
                        console.log(e.errors);
                    }
                } else {
                    // if no validation schema, count as valid
                    return (validGroupsCount = validGroupsCount + 1);
                }
            });

            setCompleteCount((validGroupsCount / totalApplicableGroups) * 100);
        }
        // eslint-disable-next-line
    }, [quoteData]);

    return completeCount;
};
