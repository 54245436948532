import { Box, Flex, Grid, Text, Link } from '@theme-ui/components';
import { Form, Formik } from 'formik';
import { FormSection } from 'components/Common/FormSection';
import { FieldGroup } from 'components/Common/FieldGroup';
import { Submit } from 'components/FormInputs/Submit';
import { TextInput } from 'components/FormInputs/TextInput';
import { TextArea } from 'components/FormInputs/TextArea';
import dayjs from 'dayjs';
import { useApproveDealerQuote } from './hooks/useApproveDealerQuote';
import { thousandSeparated } from 'utils/formatting';

export const QuoteForm = ({ dealer, quoteData, handleBackToQuotes, onApprove, ...props }) => {
    const approveDealerQuote = useApproveDealerQuote();

    return (
        <Formik
            enableReinitialize
            onSubmit={async (values) => {
                const response = await approveDealerQuote.mutateAsync(dealer.uuid);
                if (response !== null && 'function' === typeof onApprove) {
                    onApprove(dealer);
                }
            }}
            initialValues={{
                dealer_reference: dealer?.dealer_reference ?? '',
                quote_number: quoteData.quote_id ?? '',
                quote_date: dayjs(dealer.created_at).format('DD/MM/YYYY @ h:mma'),
                email: dealer?.email ?? '',
                dealership: dealer.dealership ?? '',
                attention_to: dealer.attention_to ?? '',

                client_email: quoteData?.personal?.email ?? '',
                client_name: quoteData?.personal?.name ?? '',

                vehicle_model_year: quoteData?.vehicle?.model_year ?? '',
                vehicle_make: quoteData?.vehicle?.make ?? '',
                vehicle_model: quoteData?.vehicle?.model ?? '',
                vehicle_variant: quoteData?.vehicle?.variant ?? '',
                fleet_status: dealer.fleet_status ?? '',
                vehicle_availability: dealer.vehicle_availability ?? '',
                colour_availability: dealer.colour_availability ?? '',
                comments: dealer.comments ?? '',

                pricing_retail_price: `$${thousandSeparated(dealer.pricing_retail_price ?? '0')}`,
                pricing_fleet_discount: `$${thousandSeparated(dealer.pricing_fleet_discount ?? '0')}`,
                pricing_bonus: `$${thousandSeparated(dealer.pricing_bonus ?? '0')}`,
                pricing_other_discount: `$${thousandSeparated(dealer.pricing_other_discount ?? '0')}`,
                pricing_sub_total: `$${thousandSeparated(dealer.pricing_sub_total ?? '0')}`,
                pricing_delivery_charge: `$${thousandSeparated(dealer.pricing_delivery_charge ?? '0')}`,
                pricing_sub_total_after_delivery_charge: `$${thousandSeparated(dealer.pricing_sub_total_after_delivery_charge ?? '0')}`,
                pricing_gst: `$${thousandSeparated(dealer.pricing_gst ?? '0')}`,
                pricing_sub_total_after_gst: `$${thousandSeparated(dealer.pricing_sub_total_after_gst ?? '0')}`,
                pricing_luxury_tax: `$${thousandSeparated(dealer.pricing_luxury_tax ?? '0')}`,
                pricing_registration: `$${thousandSeparated(dealer.pricing_registration ?? '0')}`,
                pricing_ctp: `$${thousandSeparated(dealer.pricing_ctp ?? '0')}`,
                pricing_total_options: `$${thousandSeparated(dealer.pricing_total_options ?? '0')}`,
                pricing_stamp_duty: `$${thousandSeparated(dealer.pricing_stamp_duty ?? '0')}`,
                pricing_premium_plates: `$${thousandSeparated(dealer.pricing_premium_plates ?? '0')}`,
                pricing_total: `$${thousandSeparated(dealer.pricing_total ?? '0')}`,
            }}
        >
            {({ values, setFieldValue }) => {
                return (
                    <Form {...props}>
                        <Box>
                            <FormSection title="Quote / Dealership">
                                <FieldGroup title="Quote">
                                    <Grid
                                        sx={{
                                            gridTemplate: 'auto / repeat(3, 1fr)',
                                        }}
                                    >
                                        <TextInput name="quote_number" label="Catch-e Quote Number" readOnly={true} />
                                        <TextInput name="dealer_reference" label="Dealer Reference" readOnly={true} />
                                        <TextInput name="quote_date" label="Dealer Quote Submitted On" readOnly={true} />
                                    </Grid>
                                </FieldGroup>
                                <FieldGroup title="Dealership">
                                    <Grid
                                        sx={{
                                            gridTemplate: 'auto / repeat(2, 1fr)',
                                        }}
                                    >
                                        <TextInput name="dealership" label="Dealership" readOnly={true} />
                                        <TextInput name="attention_to" label="Attention to" readOnly={true} />
                                    </Grid>
                                    <TextInput name="email" readOnly={true} label="Email" />
                                </FieldGroup>
                            </FormSection>
                            <FormSection title="Driver">
                                <FieldGroup>
                                    <Grid
                                        sx={{
                                            gridTemplate: 'auto / repeat(2, 1fr)',
                                        }}
                                    >
                                        <TextInput name="client_name" label="Driver Name" readOnly={true} />
                                        <TextInput name="client_email" label="Driver Email" readOnly={true} />
                                    </Grid>
                                </FieldGroup>
                            </FormSection>
                            <FormSection title="Vehicle">
                                <FieldGroup>
                                    <Grid
                                        sx={{
                                            gridTemplate: 'auto / repeat(2, 1fr)',
                                        }}
                                    >
                                        <TextInput name="vehicle_make" label="Vehicle Make" readOnly={true} />
                                        <TextInput name="vehicle_model" label="Vehicle Model" readOnly={true} />
                                        <TextInput name="vehicle_model_year" label="Year of Manufacture" readOnly={true} />
                                        <TextInput name="vehicle_variant" label="Variant" readOnly={true} />
                                    </Grid>
                                </FieldGroup>
                                <FieldGroup>
                                    <Grid
                                        sx={{
                                            gridTemplate: 'auto / repeat(3, 1fr)',
                                        }}
                                    >
                                        <TextInput name="fleet_status" label="Fleet Status" readOnly={true} />
                                        <TextInput name="vehicle_availability" label="Vehicle Availability" readOnly={true} />
                                        <TextInput name="colour_availability" label="Colour Availability" readOnly={true} />
                                    </Grid>
                                </FieldGroup>
                            </FormSection>
                            <FormSection title="Vehicle Price">
                                <FieldGroup>
                                    <Grid
                                        sx={{
                                            gridTemplate: 'auto / repeat(2, 1fr)',
                                            mb: 3,
                                        }}
                                    >
                                        <TextInput name="pricing_retail_price" label="Retail Price" groupSx={{ mb: 0 }} readOnly={true} />

                                        <TextInput name="pricing_fleet_discount" label="Fleet Discount" groupSx={{ mb: 0 }} readOnly={true} />

                                        <TextInput name="pricing_bonus" label="Bonus" groupSx={{ mb: 0 }} readOnly={true} />

                                        <TextInput name="pricing_other_discount" label="Other Discount" groupSx={{ mb: 0 }} readOnly={true} />
                                    </Grid>

                                    <TextInput name="pricing_sub_total" label="Sub Total" readOnly={true} />
                                </FieldGroup>
                            </FormSection>

                            {Array.isArray(dealer.vehicle_options) ? (
                                <FormSection title="Vehicle Options">
                                    <FieldGroup>
                                        {dealer.vehicle_options.map((option, index) => (
                                            <Flex
                                                key={index}
                                                sx={{
                                                    display: 'inline-flex',
                                                    justifyContent: 'space-between',
                                                    alignItems: 'center',
                                                    maxWidth: '300px',
                                                    py: 2,
                                                }}
                                            >
                                                <Text>{option.label}</Text>

                                                <Text as="p" variant="label">
                                                    ${thousandSeparated(option.value)}
                                                </Text>
                                            </Flex>
                                        ))}

                                        <Grid
                                            sx={{
                                                gridTemplate: 'auto / repeat(2, 1fr)',
                                                my: 3,
                                            }}
                                        >
                                            <TextInput name="pricing_total_options" label="Total Options" groupSx={{ mb: 0 }} readOnly={true} />
                                        </Grid>
                                    </FieldGroup>
                                </FormSection>
                            ) : null}

                            <FormSection title="Vehicle On Roads / Government Charges">
                                <FieldGroup>
                                    <TextInput name="pricing_delivery_charge" label="Delivery Charge" readOnly={true} />

                                    <TextInput name="pricing_sub_total_after_delivery_charge" label="Sub Total (After Delivery)" readOnly={true} />
                                </FieldGroup>

                                <FieldGroup>
                                    <TextInput name="pricing_gst" label="GST" readOnly={true} />

                                    <TextInput name="pricing_sub_total_after_gst" label="Sub Total (After GST)" readOnly={true} />
                                </FieldGroup>

                                <FieldGroup>
                                    <Grid
                                        sx={{
                                            gridTemplate: 'auto / repeat(2, 1fr)',
                                            mb: 3,
                                        }}
                                    >
                                        <TextInput name="pricing_luxury_tax" label="Luxury Tax" groupSx={{ mb: 0 }} readOnly={true} />

                                        <TextInput name="pricing_registration" label="Registration" groupSx={{ mb: 0 }} readOnly={true} />

                                        <TextInput name="pricing_ctp" label="CTP" groupSx={{ mb: 0 }} readOnly={true} />

                                        <TextInput name="pricing_premium_plates" label="Premium Plates" groupSx={{ mb: 0 }} readOnly={true} />
                                    </Grid>

                                    <TextInput name="pricing_stamp_duty" label="Stamp Duty" readOnly={true} />

                                    <TextInput name="pricing_total" label="Total (Inc GST)" readOnly={true} />
                                </FieldGroup>
                            </FormSection>

                            <FieldGroup>
                                <TextArea name="comments" label="Comments" readOnly={true} />
                            </FieldGroup>

                            <Flex
                                sx={{
                                    flexDirection: 'column',
                                    my: 4,
                                    maxWidth: '320px',
                                    mx: 'auto',
                                }}
                            >
                                <Submit
                                    text="Accept Quote"
                                    sx={{
                                        mb: 3,
                                        width: '100%',
                                        flex: 1,
                                    }}
                                />
                                <Link
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handleBackToQuotes();
                                    }}
                                    sx={{ cursor: 'pointer', fontWeight: 'bold', textAlign: 'center' }}
                                >
                                    Back to Quotes
                                </Link>
                            </Flex>
                        </Box>
                    </Form>
                );
            }}
        </Formik>
    );
};
