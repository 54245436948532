import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import api from 'config/api';

export const useConfirmQuote = () => {
    const queryClient = useQueryClient();

    return useMutation(
        async ({ quoteUuid, ...values }) => {
            try {
                let response = await api.post(`/quote/${quoteUuid}`, {
                    ...values,
                });
                if (response.data.success) {
                    return response.data;
                }
            } catch (e) {
                toast.error(e.response?.data?.message, {
                    toastId: 'confirm-quote-error'
                });
                return e.response?.data;
            }
        },
        {
            onSuccess: (data) => {
                if (data.success) {
                    queryClient.setQueryData('quote', data.data);
                }
            },
        }
    );
};
