import { useEffect } from 'react';
import axios from 'axios';
import { ThemeProvider } from 'theme-ui';
import { theme } from './theme';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-circular-progressbar/dist/styles.css';

import 'fonts/fonts.css';
import { AppWrapper } from 'components/Common/AppWrapper';
import { Header } from 'components/Layout/Header';

// routes
import { Footer } from 'components/Layout/Footer';
import { DealerLink } from 'pages/DealerLink';
import { DealerQuote } from 'pages/DealerQuote';
import { DealerQuotes } from 'pages/DealerQuotes';
import { Onboard } from 'pages/Onboard';
import { Quote } from 'pages/Quote';
import { MarkdownProvider } from 'context/MarkdownContext';
import { AppProvider } from 'context/AppContext';

// Turn off console logs for production
if(process.env.NODE_ENV === 'production'){
    console.log = () => {};
}

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: false,
            refetchOnWindowFocus: false,
        },
    },
});

const App = ({ children, ...props }) => {
    useEffect(() => {
        // fetch CSRF token
        (async () => {
            await axios.get(`${process.env.REACT_APP_API_BASE_URL}/sanctum/csrf-cookie`, {
                withCredentials: true,
            });
        })();
    }, []);

    return (
        <ThemeProvider useRootStyles={true} theme={theme}>
            <QueryClientProvider client={queryClient}>
                <ReactQueryDevtools initialIsOpen={false} />

                <Router>
                    <AppProvider>
                        <MarkdownProvider>
                            <AppWrapper>
                                <Header />
                                <Switch>
                                    <Route path="/dealerlink" exact>
                                        <DealerLink />
                                    </Route>

                                    <Route path="/dealerquote/:accessToken?">
                                        <DealerQuote />
                                    </Route>

                                    <Route path="/quote/:quoteUuid?" exact>
                                        <Quote />
                                    </Route>

                                    <Route path="/quote/:quoteUuid?/dealerquotes">
                                        <DealerQuotes />
                                    </Route>

                                    <Route path="/onboard/:quoteId?">
                                        <Onboard />
                                    </Route>

                                    <Redirect to="/onboard/" />
                                </Switch>

                                <Footer />
                            </AppWrapper>
                        </MarkdownProvider>
                        {/* react-toastify container*/}
                        <ToastContainer />
                    </AppProvider>
                </Router>
            </QueryClientProvider>
        </ThemeProvider>
    );
};

export default App;
