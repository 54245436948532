import { Text, Label, Checkbox as CheckboxUi, Box } from '@theme-ui/components';
import { useField } from 'formik';
import { MarkdownStyled } from './styled';

import { Error } from '../Error';

export const Checkbox = ({
    name,
    apiName,
    label,
    checkboxTermsLink,
    sx,
    onChange,
    mdContent,
    ...props
}) => {
    // eslint-disable-next-line
    const [field, meta, { setValue }] = useField({ name, type: 'checkbox'});

    return (
        <>
            <Box>{mdContent && <MarkdownStyled>{mdContent}</MarkdownStyled>}</Box>

            <Box sx={{ position: 'relative' }}>
                <Error text={meta.error} isVisible={meta.touched && meta.error} />

                <Label
                    sx={{
                        fontSize: 1,
                        display: 'flex',
                        alignItems: 'center',
                        cursor: 'pointer',
                        ...sx,
                    }}
                >
                    <CheckboxUi
                        sx={{
                            width: '34px !important',
                            height: '34px !important',
                            position: 'relative',
                            top: '-2px',
                            path: { strokeWidth: '1px !important', fill: '#DE0A1C' },
                            'input:focus ~ &': {
                                outline: 'none',
                                background: 'rgb(255 226 226);',
                            },
                        }}
                        {...field}
                        {...props}
                        onChange={(event) => {
                            const { checked } = event.target;
                            setValue(checked);
                            onChange && onChange({ name: apiName, value: checked });
                        }}
                    />

                    <Text as="span" sx={{ ml: 0, fontSize: '13px', fontWeight: 500 }}>
                        {label} {checkboxTermsLink && checkboxTermsLink()}
                    </Text>
                </Label>
            </Box>
        </>
    );
};
