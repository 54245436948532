import { Box } from '@theme-ui/components';

export const FixedContentBox = ({
  children,
  sx,
  innerRef,
  ...props
}) => {
    return (
        <Box
            sx={{
              bg: 'white',
              borderRadius: ['30px 30px 0 0', 30],
              p: '25px 20px 0 20px',
              overflow: 'hidden',
              ...sx
            }}
            ref={innerRef}
            {...props}
        >
            {children}
        </Box>
    );
};
