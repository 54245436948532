import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

import api from 'config/api';

export const useOnboard = () => {
    const onboardUser = useMutation(async (values) => {
        try {
            let response = await api.post(`/onboard`, values);
            if (response.data.success) {
                return response.data;
            }
        } catch (e) {
            toast.error(e.response?.data?.message, {
                toastId: 'onboard-error'
            });
        }
    });

    return onboardUser;
};
