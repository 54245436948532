import { Box, Grid, Text, Input, Link } from '@theme-ui/components';
import { Form, Formik } from 'formik';
import { createRef, useState } from 'react';
import { motion } from 'framer-motion';
import * as Yup from 'yup';

import { Button } from 'components/Common/Button';
import { Container } from 'components/Common/Container';
import { ContentBox } from 'components/Common/ContentBox';
import { Headline } from 'components/Common/Headline';
import { TextInput } from 'components/FormInputs/TextInput';
import { RecaptchaField } from 'components/FormInputs/RecaptchaField';
import { Submit } from 'components/FormInputs/Submit';
import { fadeIn } from 'utils/animations';
import { toast } from 'react-toastify';
import { DealerLinkLimits } from 'utils/constants';
import { useDealerLink } from './hooks/useDealerLink';
import { useSendDealerLink } from './hooks/useSendDealerLink';

const BoxMotion = ({ children, ...props }) => {
    return (
        <motion.div {...fadeIn} {...props}>
            {children}
        </motion.div>
    );
};

export const DealerLink = ({ ...props }) => {
    const [showConfirm, setShowConfirm] = useState(false);
    const [data, setData] = useState(null);
    const [dealerDetails, setDealerDetails] = useState([]);
    const dealerLink = useDealerLink();
    const sendDealerLink = useSendDealerLink();
    const recaptchaRef = createRef();
    const recaptchaEnabled = process.env.REACT_APP_GOOGLE_RECAPTCHA === 'true';
    const handleCopyDealerLink = () => {
        navigator.clipboard.writeText(data.share_url);
        toast.success('Link copied!', {
            toastId: 'dealerquote-share-success',
        });
    };

    const handleSendToDealerNow = async (e) => {
        e.preventDefault();

        const response = await sendDealerLink.mutateAsync(data.token);
        if (response !== null) {
            toast.success(response?.data?.message ?? 'We will send the link to the dealer shortly', {
                toastId: 'dealerquote-send-success',
            });
            setShowConfirm(false);
            setData(null);
        }
    };
    const handleReloadForm = () => {
        setShowConfirm(false);
        setData(null);
    };

    const handleDealerLinkSubmit = async (values) => {
        let token = null;
        let formData = {
            email: values.email,
            quote_id: values.quote_id,
        };
        if (recaptchaEnabled) {
            token = recaptchaRef.current.getValue();
            if (!token) {
                token = await recaptchaRef.current.executeAsync();
            }
        }
        if (token) {
            formData['recaptcha'] = token;
        }
        if (token || !recaptchaEnabled) {
            const response = await dealerLink.mutateAsync(formData);
            if (response !== null) {
                setData(response?.data);

                const dealer = response?.data.dealer;
                let dealerArray = [];
                let dealerAddress = [];
                if (dealer?.email) {
                    dealerArray.push(`Email: ${dealer.email}`);
                }
                if (dealer?.phone) {
                    dealerArray.push(`Phone: ${dealer.phone}`);
                }
                if (dealer?.address?.address_1) {
                    dealerArray.push(dealer?.address?.address_1);
                }
                if (dealer?.address?.address_2) {
                    dealerArray.push(dealer?.address?.address_2);
                }
                if (dealer?.address?.suburb) {
                    dealerAddress.push(dealer?.address?.suburb);
                }
                if (dealer?.address?.state) {
                    dealerAddress.push(dealer?.address?.state);
                }
                if (dealer?.address?.postcode) {
                    dealerAddress.push(dealer?.address?.postcode);
                }
                if (dealerAddress.length > 0) {
                    dealerArray.push(dealerAddress.join(' '));
                }
                setDealerDetails(() => dealerArray);
                setShowConfirm(true);
            }
        } else {
            toast.error('We could not verify the captcha request.', {
                toastId: 'recaptcha-error',
            });
        }
    };

    const renderContent = () => {
        if (!showConfirm) {
            return (
                <BoxMotion key="form">
                    <Headline sx={{ color: 'primary', mb: 3 }}>Dealer Access</Headline>
                    <Text as="p" variant="lead" mb={4}>
                        Please verify the quote and easi consultant's email to grab a vehicle submission access link for the dealer.
                    </Text>

                    <Box sx={{ maxWidth: 320, mx: 'auto' }}>
                        <Formik
                            enableReinitialize
                            onSubmit={handleDealerLinkSubmit}
                            validationSchema={Yup.object({
                                email: Yup.string().email('Must be a valid email address').required("Driver's email is required"),
                                quote_id: Yup.string().required('Quote ID is required'),
                            })}
                            initialValues={{
                                email: '',
                                quote_id: '',
                            }}
                        >
                            {() => (
                                <Form>
                                    <TextInput name="quote_id" label="Quote ID" mb={3} />

                                    <TextInput name="email" label="Easi Consultant's Email" mb={3} />

                                    {recaptchaEnabled && <RecaptchaField field="recaptcha" name="recaptcha" inputRef={recaptchaRef} />}

                                    <Submit text="Get Access Link" sx={{ width: 'full' }} />
                                </Form>
                            )}
                        </Formik>
                    </Box>
                </BoxMotion>
            );
        } else {
            return (
                <BoxMotion key="confirmation">
                    <Headline sx={{ color: 'primary', mb: 3 }}>Dealer Access</Headline>

                    <Text as="p" variant="lead" mb={4}>
                        Copy and share the link with a dealer
                    </Text>

                    <Input
                        readOnly="readonly"
                        variant="readonly"
                        onClick={handleCopyDealerLink}
                        defaultValue={data.share_url}
                        sx={{
                            minWidth: 550,
                            py: 3,
                            mb: 2,
                            textAlign: 'center',
                            '&:hover': {
                                cursor: 'pointer',
                            },
                        }}
                    />

                    <Text as="p" mb={4}>
                        This link will have access for {DealerLinkLimits.expiry} and up to {DealerLinkLimits.max_dealer_quotes_per_quote} vehicles for it
                    </Text>

                    <Box
                        sx={{
                            maxWidth: 420,
                            mx: 'auto',
                            border: '1px solid',
                            borderColor: 'primary',
                            borderRadius: 2,
                            px: 4,
                            py: 3,
                            mb: 4,
                        }}
                    >
                        <Text as="p" sx={{ textAlign: 'left' }}>
                            <strong>Dealership details:</strong>
                            <br />
                            {data.dealer?.name}
                            <br />
                            {dealerDetails.length > 0 ? (
                                <>
                                    {dealerDetails.map((line, index) => (
                                        <Text as="span" key={`dealer-detail-${index}`}>
                                            {line}
                                            <br />
                                        </Text>
                                    ))}
                                </>
                            ) : null}
                        </Text>
                    </Box>

                    <Grid gap={2} mb={3} columns={['1fr', '1fr', 'repeat(2, 1fr)']}>
                        <Button onClick={handleCopyDealerLink} variant="secondary" sx={{ width: '100%' }} title="Copy the link to send to the dealer manually">
                            Copy Link
                        </Button>
                        <Button onClick={handleSendToDealerNow} sx={{ width: '100%' }} title="Let the system send the link to the dealer">
                            Send To Dealer Now
                        </Button>
                    </Grid>

                    <Text as="p">
                        <Link onClick={handleReloadForm} sx={{ cursor: 'pointer', fontWeight: 'bold' }}>
                            Get another link
                        </Link>
                    </Text>
                </BoxMotion>
            );
        }
    };

    return (
        <Container>
            <ContentBox
                sx={{
                    textAlign: 'center',
                    maxWidth: 660,
                    mx: 'auto',
                    p: [4, 6],
                    minHeight: '416px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                {renderContent()}
            </ContentBox>
        </Container>
    );
};
