import { useState } from 'react';
import { Box, Button, Text } from '@theme-ui/components';
import { FieldArray, useField } from 'formik';
import { AnimatePresence, motion } from 'framer-motion';

import { Icon } from 'components/Common/Icon';
import { FormFields } from 'pages/Dashboard/components/FormFields';
import useDidMountEffect from 'hooks/useDidMountEffect';
import { useDebounce } from 'hooks/useDebounce';

const BoxMotion = motion(Box);

export const Repeater = ({ name, apiName, label, title, description, fields, rowName, onChange, rowNameAsTitle, rowDescription, minRows, maxRows, rowSx, showRemoveText, defaultRowValue, ...props }) => {
    const debouncedSave = useDebounce((nextValue) => onChange(nextValue), 750);

    // use this for useDidMountEffect
    // eslint-disable-next-line
    const [state, setState] = useState({
        key: false,
    });

    //eslint-disable-next-line
    const [field, meta, helpers] = useField(name);
    let { value } = field;

    //make sure we change the object back to an array so we can iterate through it with formik FieldArray
    let valueArray = Array.isArray(value) && value !== undefined ? [...value] : [];

    // if minimum rows prop is set, fill out the initial value to match
    // if (valueArray.length < minRows) {
    //     [...Array(minRows - valueArray.length)].forEach((_, i) => {
    //         valueArray.push({});
    //     });
    // }

    // custom useEffect hook to prevent form from running on initial mount
    useDidMountEffect(() => {
        if (onChange) {
            debouncedSave({ name: apiName, value: valueArray, repeater: true });
        }
        // run if 'key' changes, but not on initial render
    }, [state.key, value]);

    return (
        <Box mb={3}>
            {title && (
                <Text as="p" variant="lead" sx={{ mb: description ? 2 : 3 }}>
                    {title}
                </Text>
            )}

            {description && (
                <Text as="p" variant="label" sx={{ mb: '22px', color: 'grayDark' }}>
                    {description}
                </Text>
            )}

            <FieldArray
                sx={{ mb: 3 }}
                name={name}
                render={(arrayHelpers) => {
                    return (
                        <AnimatePresence>
                            {valueArray.length > 0 &&
                                valueArray.map((question, index) => {
                                    return (
                                        <BoxMotion
                                            key={`repeater-${index}`}
                                            variants={{
                                                repeaterOpen: {
                                                    opacity: 1,
                                                    height: 'auto',
                                                    transitionEnd: {
                                                        overflow: 'visible',
                                                    },
                                                },
                                                repeaterClosed: {
                                                    opacity: 0,
                                                    height: 0,
                                                    overflow: 'hidden',
                                                    transitionEnd: {
                                                        overflow: 'hidden',
                                                    },
                                                },
                                            }}
                                            initial="repeaterClosed"
                                            animate="repeaterOpen"
                                            exit="repeaterClosed"
                                        >
                                            <Box sx={rowSx}>
                                                {(rowName || rowNameAsTitle) && (
                                                    <Text as="p" variant={rowNameAsTitle ? 'lead' : 'label'} mb={'12px'}>
                                                        {rowName} {maxRows !== 1 ? index + 1 : null}
                                                    </Text>
                                                )}

                                                {rowDescription && (
                                                    <Text
                                                        as="p"
                                                        variant="label"
                                                        sx={{
                                                            mb: '22px',
                                                            color: 'grayDark',
                                                        }}
                                                    >
                                                        {rowDescription}
                                                    </Text>
                                                )}

                                                <FormFields fields={fields} repeaterName={name} repeatIndex={index} repeater={true} />

                                                {index !== 0 && index + 1 > minRows && (valueArray[index]['hideRemove'] ?? false) !== true ? (
                                                    <Box
                                                        sx={{
                                                            width: 'full',
                                                            textAlign: 'right',
                                                            mb: 3,
                                                        }}
                                                    >
                                                        <Button
                                                            type="button"
                                                            variant="pill"
                                                            onClick={() => arrayHelpers.remove(index)}
                                                            sx={{
                                                                padding: showRemoveText ? null : '5px',
                                                            }}
                                                        >
                                                            {showRemoveText && 'Remove'}
                                                            <Icon icon="cross" sx={{ pl: showRemoveText ? 2 : 0 }} />
                                                        </Button>
                                                    </Box>
                                                ) : null}
                                            </Box>
                                        </BoxMotion>
                                    );
                                })}

                            {(maxRows === false || (maxRows && valueArray.length < maxRows)) && (
                                <Box sx={{ width: 'full', textAlign: 'right' }}>
                                    <Button
                                        type="button"
                                        variant="pill"
                                        onClick={() => arrayHelpers.push({ ...defaultRowValue })} // insert an empty string at a position
                                    >
                                        Add another
                                        <Icon icon="plus" sx={{ pl: 2 }} />
                                    </Button>
                                </Box>
                            )}
                        </AnimatePresence>
                    );
                }}
            />
        </Box>
    );
};

Repeater.defaultProps = {
    rowName: '',
    name: '',
    label: '',
    questions: '',
    rowNameAsTitle: false,
    rowDescription: false,
    minRows: 1,
    maxRows: false,
};
