export const getBorderColor = (status) => {
    if (status.isDragReject) {
        return '#ff5722';
    }

    if (status.isDragActive || status.isDragAccept) {
        return '#1eb162';
    }

    return '#e0dcdc';
};
