import { Box } from '@theme-ui/components';

export const Card = ({ children, sx, ...props }) => {
    return (
        <Box
            sx={{
                bg: 'white',
                borderRadius: 15,
                p: 4,
                width: 'auto',
                boxShadow: '0px 4px 30px rgba(40, 39, 129, 0.15)',
                ...sx,
            }}
            {...props}
        >
            {children}
        </Box>
    );
};
