import { Box } from '@theme-ui/components';

export const FieldGroup = ({ children, invalid, title, ...props }) => (
  <Box sx={{
    bg: 'offWhite',
    borderRadius: 4,
    borderColor: invalid ? '#fc111d' : 'offWhite',
    px: 3,
    pt: 4,
    pb: 3,
    mb: 3,
  }}>
    {title && (
      <Box sx={{
        fontSize: '19px',
        mt: -3,
        mb: 2,
      }}>
        {title}
      </Box>
    )}
    {children}
  </Box>
)
