import api from 'config/api';
import { useQuery } from 'react-query';

const fetchQuoteData = async ({ quoteUuid, getToken }) => {

    if (getToken) {
        try {
            const bearerToken = await api.get(`/quote/token/${quoteUuid}`);

            bearerToken?.data &&
                (api.defaults.headers.common = {
                    Authorization: `Bearer ${bearerToken.data}`,
                });
        } catch (e) {
            console.log(e.response);
        }
    }

    try {
        const response = await api.get(`quote/${quoteUuid}`);
        if (response.data?.success) {
            return response.data.data;
        }
    } catch (e) {
        console.log(e);
        throw new Error(e?.response?.data?.message ?? 'Unable to find quote, please supply your Quote ID and email again');
    }
    throw new Error('Unable to find quote, please supply your Quote ID and email again');
};

export const useQuote = (quoteUuid, args = {}) => {
    return useQuery('quote', () => fetchQuoteData(quoteUuid, args));
};
