import React from 'react';
import { Box } from '@theme-ui/components';

import { ReactComponent as Phone } from './phone.svg';
import { ReactComponent as Keys } from './keys.svg';
import { ReactComponent as PenCircle } from './pen-circle.svg';
import { ReactComponent as ArrowLeft } from './arrow-left.svg';
import { ReactComponent as ArrowRight } from './arrow-right.svg';
import { ReactComponent as ArrowRightLight } from './arrow-right-light.svg';
import { ReactComponent as Calendar } from './calendar.svg';
import { ReactComponent as Plus } from './plus.svg';
import { ReactComponent as Cross } from './cross.svg';
import { ReactComponent as Tick } from './tick.svg';
import { ReactComponent as Upload } from './upload.svg';
import { ReactComponent as TickBlue } from './tick-blue.svg';
import { ReactComponent as TickLarge } from './tick-large.svg';
import { ReactComponent as LogoRed } from './logo-red.svg';
import { ReactComponent as Assistance } from './assistance.svg';
import { ReactComponent as Comprehensive } from './comprehensive.svg';
import { ReactComponent as Fuel } from './fuel.svg';
import { ReactComponent as Gap } from './gap.svg';
import { ReactComponent as Maintenance } from './maintenance.svg';
import { ReactComponent as Management } from './management.svg';
import { ReactComponent as ManagementOutline } from './management-outline.svg';
import { ReactComponent as Registration } from './registration.svg';
import { ReactComponent as Lpi } from './lpi.svg';
import { ReactComponent as Umbrella } from './umbrella.svg';
import { ReactComponent as Tyres } from './tyres.svg';
import { ReactComponent as PencilIcon } from './pencil-icon.svg';
import { ReactComponent as LockIcon } from './lock.svg';

export const Icon = ({ icon, sx, size, ...props }) => {
    const renderIcon = (icon) => {
        switch (icon) {
            case 'lock':
              return <LockIcon {...props} />;
            case 'phone':
                return <Phone {...props} />;
            case 'keys':
                return <Keys {...props} />;
            case 'arrow-left':
                return <ArrowLeft {...props} />;
            case 'arrow-right':
                return <ArrowRight {...props} />;
            case 'arrow-right-light':
                return <ArrowRightLight {...props} />;
            case 'pen-circle':
                return <PenCircle {...props} />;
            case 'calendar':
                return <Calendar {...props} />;
            case 'plus':
                return <Plus {...props} />;
            case 'cross':
                return <Cross {...props} />;
            case 'tick':
                return <Tick {...props} />;
            case 'tick-blue':
                return <TickBlue {...props} />;
            case 'tick-large':
                return <TickLarge {...props} />;
            case 'upload':
                return <Upload {...props} />;
            case 'logo-red':
                return <LogoRed {...props} />;
            case 'assistance':
                return <Assistance {...props} />;
            case 'comprehensive':
                return <Comprehensive {...props} />;
            case 'fuel':
                return <Fuel {...props} />;
            case 'gap':
                return <Gap {...props} />;
            case 'maintenance':
                return <Maintenance {...props} />;
            case 'management':
                return <Management {...props} />;
            case 'registration':
                return <Registration {...props} />;
            case 'lpi':
                return <Lpi {...props} />;
            case 'umbrella':
                return <Umbrella {...props} />;
            case 'management-outline':
                return <ManagementOutline {...props} />;
            case 'tyres':
                return <Tyres {...props} />;
            case 'pencil':
                return <PencilIcon {...props} />;
            default:
                return '';
        }
    };

    return (
        <Box
            sx={{
                display: 'inline-flex',
                alignItems: 'center',
                justifyContent: 'center',
                '> svg': {
                    width: `${size}`,
                    height: `${size}`,
                },
                ...sx,
            }}
            {...props}
        >
            {renderIcon(icon)}
        </Box>
    );
};

Icon.defaultProps = {
    // size: '100%',
};
