import React from 'react';
import { Button as ButtonUi } from 'theme-ui';

export const Button = React.forwardRef(({ children, size, sx, ...props }, ref) => {
    const getSizePadding = (size) => {
        if (!size) {
            return '18px 60px';
        }

        return {
            sm: '11px 32px',
            lg: '18px 60px',
        }[size];
    };

    return (
        <ButtonUi
            sx={{
                display: 'inline-flex',
                justifyContent: 'center',
                alignItems: 'center',
                alignSelf: 'flex-start',
                width: props.fullWidth ? '100%' : 'auto',
                fontWeight: 600,
                letterSpacing: 0.5,
                fontSize: 1,
                transition: 'easeDefault',
                transitionProperty: 'all',
                borderWidth: '2px',
                borderRadius: '5px',
                borderStyle: 'solid',
                padding: getSizePadding(size),
                cursor: props.variant === 'disabled' ? 'not-allowed' : 'pointer',
                ':active': {
                    transform: 'scale(0.98)',
                },
                ...sx,
            }}
            {...props}
        >
            {children}
        </ButtonUi>
    );
});

Button.defaultProps = {
    fullWidth: false,
};
