import React, { useState } from 'react';
import 'react-dates/initialize';
import { SingleDatePicker } from 'react-dates';
import moment from 'moment';
import _get from 'lodash.get';
import { useFormikContext, useField } from 'formik';
import { Box, Flex, Select } from '@theme-ui/components';

import { DateWrap } from './styled';
import { ReactComponent as Calendar } from '../../Common/Icon/calendar.svg';
import { Label } from '../Label';
import { Icon } from 'components/Common/Icon';
import { Error } from '../Error';

export const Date = React.forwardRef(
    (
        {
            label,
            name,
            apiName,
            defaultValue,
            disablePastDates,
            isOutsideRange,
            validIndicator,
            validationError,
            disabled,
            onChange,
            ...props
        },
        ref
    ) => {
        const [isFocused, setIsFocused] = useState(false);
        const { setFieldValue, values } = useFormikContext();
        // eslint-disable-next-line
        const [field, meta] = useField(name);

        return (
            <Box mb={3}>
                <DateWrap
                    disabled={disabled}
                    focused={isFocused}
                    invalid={meta.error !== undefined}>
                    {label && <Label htmlFor={name} text={`${label}${props.required ? '*' : ''}`} />}

                    {validationError && (
                        <Error text={meta.error} isVisible={meta.error} sx={{
                            position: 'absolute',
                            right: '50px',
                            top: '7px',
                        }} />
                    )}

                    <SingleDatePicker
                        date={_get(values, name) ? moment(_get(values, name)) : null} // momentPropTypes.momentObj or null
                        onDateChange={(date) => {
                            if (date !== null) {
                                const value = date.format('YYYY-MM-DD');
                                setFieldValue(name, value);
                                onChange && onChange({ name: apiName, value });
                            }
                        }}
                        onFocusChange={({ focused }) => {
                            setIsFocused(focused);
                        }}
                        focused={isFocused}
                        renderMonthElement={renderMonthElement}
                        id={name}
                        readOnly
                        hideKeyboardShortcutsPanel
                        displayFormat={'DD/MM/YYYY'}
                        disabled={disabled}
                        customInputIcon={<Calendar />}
                        inputIconPosition="after"
                        numberOfMonths={1}
                        isOutsideRange={() => false}
                        placeholder=""
                    />

                    {validIndicator === true &&
                        meta.value !== '' &&
                        meta.error === undefined && (
                            <Icon
                                icon="tick"
                                sx={{
                                    position: 'absolute',
                                    right: '50px',
                                    top: '50%',
                                    transform: 'translateY(-50%)'
                                }}
                            />
                        )}
                </DateWrap>
            </Box>
        );
    }
);

const renderMonthElement = ({ month, onMonthSelect, onYearSelect }) => {
    let i;
    const years = [];

    for (i = moment().year() + 20; i >= moment().year() - 100; i--) {
        years.push(
            <option value={i} key={`year-${i}`}>
                {i}
            </option>
        );
    }
    return (
        <Flex
            sx={{
                justifyContent: 'center',
                fontSize: 1,
                px: 45,
                top: '-4px',
                position: 'relative',
            }}
        >
            <Box sx={{ flexGrow: 1, mr: '5px' }}>
                <Select
                    sx={{ p: '4px 7px', border: '1px solid #e4e7e7' }}
                    value={month.month()}
                    onChange={(e) => onMonthSelect(month, e.target.value)}
                >
                    {moment.months().map((label, value) => (
                        <option value={value} key={value}>
                            {label}
                        </option>
                    ))}
                </Select>
            </Box>

            <Box sx={{ flexGrow: 1 }}>
                <Select
                    sx={{ p: '4px 7px', border: '1px solid #e4e7e7' }}
                    value={month.year()}
                    onChange={(e) => onYearSelect(month, e.target.value)}
                >
                    {years}
                </Select>
            </Box>
        </Flex>
    );
};

Date.defaultProps = {
    validationError: true,
    placeholder: '',
    validIndicator: true,
    required: false,
};
