/** @jsxImportSource theme-ui */
import { Box, Flex } from '@theme-ui/components';
import { Themed } from '@theme-ui/mdx';
import { Icon } from 'components/Common/Icon';
import { AnimatePresence, motion } from 'framer-motion';
import { useToggle } from 'hooks/useToggle';

const BoxMotion = motion(Box);

export const FormSection = ({
  title,
  children,
  isOpen: initiallyOpen,
}) => {
  const [isOpen, setIsOpen] = useToggle(initiallyOpen);

  return (
    <Box
        sx={{
            borderBottom: '1px solid',
            borderColor: 'grayLight',
            position: 'relative',
            bg: 'white',
        }}
    >
        <Flex
            tabIndex="0"
            onKeyPress={(e) => {
                if(e.key === 'Enter'){
                    setIsOpen(!isOpen);
                }
            }}
            onClick={() => {
                setIsOpen(!isOpen);
            }}
            sx={{
                justifyContent: 'space-between',
                px: [4, null, null, 0],
                pb: 4,
                pt: 4,
                cursor: 'pointer',
                transition: 'easeDefault',
                transitionProperty: 'color',
                '&:hover': {
                    color: 'secondary',
                },
                '&:focus': {
                    color: 'secondary',
                    outline: 'none'
                }
            }}
        >
            <Themed.h5 sx={{ margin: 0, color: 'inherit' }}>
                {title}
            </Themed.h5>

            <Flex sx={{ alignItems: 'center' }}>
                <Icon
                    icon="arrow-right-light"
                    sx={{
                        transition: 'easeDefault',
                        transitionProperty: 'transform',
                        transform: isOpen ? 'rotate(45deg)' : 'none',
                    }}
                />
            </Flex>
        </Flex>

        <AnimatePresence initial={false}>
            <BoxMotion
                initial={isOpen ? 'open' : 'collapsed'}
                animate={isOpen ? 'open' : 'collapsed'}
                exit="collapsed"
                variants={{
                    open: {
                        opacity: 1,
                        height: 'auto',
                        transitionEnd: {
                            overflow: 'visible',
                        },
                    },
                    collapsed: {
                        opacity: 0,
                        height: 0,
                        overflow: 'hidden',
                        transitionEnd: {
                            overflow: 'hidden',
                        },
                    },
                }}
                transition={{
                    duration: 0.5,
                }}
                sx={{
                    bg: 'white',
                    pointerEvents: isOpen ? 'auto' : 'none',
                    zIndex: isOpen ? -1 : 2,
                    overflow: 'hidden',
                }}
            >
                <Box sx={{ px: [15, null, null, 0], pb: 4 }}>
                    {children}
                </Box>
            </BoxMotion>
        </AnimatePresence>
    </Box>
  )
}
