import { Error } from '../Error';
import { useField } from 'formik';
import ReCAPTCHA from 'react-google-recaptcha';

export const RecaptchaField = ({ name, inputRef, ...props }) => {
    // eslint-disable-next-line
    const [field, meta, { setValue }] = useField(name);

    const handleOnChange = (response) => {
        setValue(response);
    };

    return (
        <>
            <ReCAPTCHA
                ref={inputRef}
                onChange={handleOnChange}
                size="invisible"
                sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE}
            />
            {meta?.error &&
                <Error
                    text={meta?.error}
                    isVisible={meta?.error}
                    sx={{
                        mb: 4
                    }}
                />
            }
        </>
    );
};

RecaptchaField.defaultProps = {
    name: 'recaptcha'
};
