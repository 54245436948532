import _debounce from 'lodash.debounce';
import { useCallback } from 'react';

export const useDebounce = (callback, delay) => {
    // eslint-disable-next-line
    const debouncedFn = useCallback(
        _debounce((...args) => callback(...args), delay),
        [delay]
    );

    return debouncedFn;
};
