import api from 'config/api';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';

const getCountries = async () => {
    try {
        const response = await api.get('/countries');
        return response.data.data;
    } catch (e) {
        toast.error(e?.response?.data?.message, {
            toastId: 'get-countries-error'
        });
        return e;
    }
};

export const useCountries = (args = {}) => {
    return useQuery('countries', getCountries, args);
};
