/** @jsxImportSource theme-ui */
import styled from '@emotion/styled';
import Markdown from 'markdown-to-jsx';
import { theme } from 'theme';

export const MarkdownStyled = styled(Markdown)`
    blockquote {
        margin: 1em 0;
        border: 1px solid ${theme.colors.primary};
        padding: 1em 1.25em;
        font-weight: ${theme.fontWeights.bold};
    }
`;
