import { Box, Text } from '@theme-ui/components';
import { useCountUp } from 'react-countup';
import { DigitBox } from './DigitBox';

export const Counter = ({ sx, start, end, ...props }) => {
    const { countUp } = useCountUp({
        start: start,
        end: end,
        delay: 0,
        duration: 1.5,
        prefix: 0,
    });

    const count = ('' + countUp).split('') || [];

    return (
        <Box
            sx={{
                display: 'flex',
                flexWrap: 'nowrap',
                justifyContent: 'center',
                alignItems: 'center',
                ...sx,
            }}
            {...props}
        >
            <Text as="span" variant="intro" sx={{ color: 'white', mr: 2 }}>
                $
            </Text>
            {count &&
                count.length &&
                count.map((digit, index) => <DigitBox key={index} digit={digit} />)}
        </Box>
    );
};

Counter.defaultProps = {
    start: 1000,
    end: 1281,
};
