import { Box, Text, Image, Grid } from '@theme-ui/components';
import { Form, Formik } from 'formik';
import { createRef, useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { useParams } from 'react-router';
import { Redirect } from 'react-router-dom';
import * as Yup from 'yup';

import { Button } from 'components/Common/Button';
import { LoadingScreen } from 'components/Common/LoadingScreen';
import { Container } from 'components/Common/Container';
import { ContentBox } from 'components/Common/ContentBox';
import { Headline } from 'components/Common/Headline';
import { Select } from 'components/FormInputs/Select';
import { TextInput } from 'components/FormInputs/TextInput';
import { TextArea } from 'components/FormInputs/TextArea';
import { Number } from 'components/FormInputs/Number';
import { Repeater } from 'components/FormInputs/Repeater';
import { RecaptchaField } from 'components/FormInputs/RecaptchaField';
import { Submit } from 'components/FormInputs/Submit';
import { fadeIn } from 'utils/animations';
import { useDealerQuote } from './hooks/useDealerQuote';
import { useSubmitDealerQuote } from './hooks/useSubmitDealerQuote';
import { toast } from 'react-toastify';
import { FormSection } from 'components/Common/FormSection';
import { FieldGroup } from 'components/Common/FieldGroup';
import { ensureNegative, thousandSeparated } from 'utils/formatting';

const BoxMotion = ({ children, ...props }) => {
    return (
        <motion.div {...fadeIn} {...props}>
            {children}
        </motion.div>
    );
};

export const DealerQuote = ({ ...props }) => {
    const [showConfirm, setShowConfirm] = useState(false);
    const [data, setData] = useState(null);
    const [vehicleOptions, setVehicleOptions] = useState(null);
    const [fleetStatusOptions, setFleetStatusOptions] = useState(null);
    let { accessToken } = useParams();
    const submitDealerQuote = useSubmitDealerQuote();
    const recaptchaRef = createRef();
    const recaptchaEnabled = process.env.REACT_APP_GOOGLE_RECAPTCHA === 'true';

    const { isLoading, isError, data: dealerQuote, error } = useDealerQuote(accessToken);

    const handleReloadForm = () => {
        window.location.reload();
    };

    useEffect(() => {
        if (dealerQuote) {
            if (vehicleOptions === null) {
                setVehicleOptions(dealerQuote?.vehicle?.optional_equipment.map((equipment) => ({ label: equipment.description, value: equipment.optional_equipment_id })));
            }
            if (fleetStatusOptions === null) {
                setFleetStatusOptions(dealerQuote?.vehicle?.fleet_status_options.map((fleet_status) => ({ label: fleet_status.name, value: fleet_status.discount_type_id })));
            }
        }
    }, [dealerQuote, vehicleOptions, fleetStatusOptions]);

    if (isLoading) {
        return <LoadingScreen />;
    }

    if (isError) {
        console.log(error);
        toast.error(error.message, {
            toastId: 'quote-load-error',
        });
        return <Redirect to="/" />;
    }

    const renderContent = () => {
        // Did we find a quote?
        if (dealerQuote === null) {
            return (
                <BoxMotion key="confirmation">
                    <Headline sx={{ color: 'primary', mb: 3 }}>Sorry!</Headline>
                    <Text as="p" variant="lead" mb={4}>
                        But the link you have supplied doesn't match any in our system.
                    </Text>
                </BoxMotion>
            );

            // Is it still valid
        } else if (!dealerQuote?.can_submit_more) {
            return (
                <BoxMotion key="confirmation">
                    <Headline sx={{ color: 'primary', mb: 3 }}>Sorry!</Headline>
                    <Text as="p" variant="lead" mb={4}>
                        But the link you have supplied has expired
                    </Text>
                </BoxMotion>
            );
        }

        if (!showConfirm) {
            return (
                <BoxMotion key="form">
                    <Headline sx={{ color: 'primary', mb: 3 }}>Dealer Quote</Headline>
                    <Text as="p" variant="lead" mb={4}>
                        Submit a vehicle to quote #{dealerQuote?.quote_id} for {dealerQuote?.driver_name}
                    </Text>

                    <Text as="p" variant="lead" mb={4}>
                        {dealerQuote?.vehicle?.model_year} {dealerQuote?.vehicle?.make} {dealerQuote?.vehicle?.model} {dealerQuote?.vehicle?.variant}
                    </Text>

                    {dealerQuote?.vehicle?.photo && (
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                mb: 3,
                                borderRadius: 8,
                                overflow: 'hidden',
                            }}
                        >
                            <Image src={dealerQuote?.vehicle.photo} />
                        </Box>
                    )}

                    <Box
                        sx={{
                            mx: 'auto',
                            border: '1px solid',
                            borderColor: 'primary',
                            borderRadius: 2,
                            px: 4,
                            py: 3,
                            mb: 4,
                        }}
                    >
                        <Headline as="h2" variant="h2" sx={{ color: 'primary', mb: 3, textAlign: 'left' }}>
                            Driver
                        </Headline>

                        <Box sx={{ textAlign: 'left' }}>
                            <Box sx={{ display: 'flex' }}>
                                <Box>
                                    <strong>Driver:</strong>
                                </Box>
                                <Box ml={'auto'}>{dealerQuote?.driver_name ?? '-'}</Box>
                            </Box>
                            <Box sx={{ display: 'flex' }}>
                                <Box>
                                    <strong>Employer:</strong>
                                </Box>
                                <Box ml={'auto'}>{dealerQuote?.employer ?? '-'}</Box>
                            </Box>
                            <Box sx={{ display: 'flex' }}>
                                <Box>
                                    <strong>Employer ABN:</strong>
                                </Box>
                                <Box ml={'auto'}>{dealerQuote?.employer_abn ?? '-'}</Box>
                            </Box>
                            <Box sx={{ display: 'flex' }}>
                                <Box>
                                    <strong>State:</strong>
                                </Box>
                                <Box ml={'auto'}>{dealerQuote?.state ?? '-'}</Box>
                            </Box>
                        </Box>
                    </Box>

                    {dealerQuote?.using_previous_quote ? (
                        <Text as="p" variant="lead" sx={{ fontWeight: 'bold' }} mb={4}>
                            To save time, we've pre-filled the details from your most recent quote for this link.
                        </Text>
                    ) : null}

                    <Box sx={{ mx: 'auto' }}>
                        <Formik
                            enableReinitialize
                            onSubmit={async (values) => {
                                let token = null;
                                let formData = {
                                    token: accessToken,
                                    email: values.email,
                                    dealership: values.dealership,
                                    attention_to: values.attention_to,
                                    fleet_status: values.fleet_status,
                                    vehicle_availability: values.vehicle_availability,
                                    colour_availability: values.colour_availability,
                                    comments: values.comments,
                                    pricing_retail_price: values.pricing_retail_price,
                                    pricing_fleet_discount: values.pricing_fleet_discount,
                                    pricing_bonus: values.pricing_bonus,
                                    pricing_other_discount: values.pricing_other_discount,
                                    pricing_delivery_charge: values.pricing_delivery_charge,
                                    pricing_luxury_tax: values.pricing_luxury_tax,
                                    pricing_registration: values.pricing_registration,
                                    pricing_ctp: values.pricing_ctp,
                                    pricing_premium_plates: values.pricing_premium_plates,
                                    pricing_stamp_duty: values.pricing_stamp_duty,
                                    vehicle_options: values.vehicle_options,
                                };

                                localStorage.setItem('dealership', values.dealership);
                                localStorage.setItem('dealership_email', values.email);
                                localStorage.setItem('dealership_attention_to', values.attention_to);

                                if (recaptchaEnabled) {
                                    token = recaptchaRef.current.getValue();
                                    if (!token) {
                                        token = await recaptchaRef.current.executeAsync();
                                    }
                                }
                                if (token) {
                                    formData['recaptcha'] = token;
                                }
                                if (token || !recaptchaEnabled) {
                                    const response = await submitDealerQuote.mutateAsync(formData);
                                    if (response !== null) {
                                        setData(response?.data);
                                        setShowConfirm(true);
                                    }
                                } else {
                                    toast.error('We could not verify the captcha request.', {
                                        toastId: 'recaptcha-error',
                                    });
                                }
                            }}
                            validationSchema={Yup.object({
                                email: Yup.string().email('Must be a valid email address').required('Email is required'),
                                dealership: Yup.string().required('Dealership is required'),
                                attention_to: Yup.string().required('Attention to is required'),
                                fleet_status: Yup.string(),
                                vehicle_availability: Yup.string().required('Vehicle availability is required'),
                                colour_availability: Yup.string().required('Colour availability is required'),
                                comments: Yup.string(),
                                pricing_retail_price: Yup.number().typeError('Retail price must be a number').min(0, 'Retail price must be equal to or greater than 0').required('Retail price is required'),
                                pricing_fleet_discount: Yup.number().typeError('Fleet discount must be a number').max(0, 'Fleet discount must be less than 0'),
                                pricing_bonus: Yup.number().typeError('Bonus discount must be a number').max(0, 'Bonus discount must be less than 0'),
                                pricing_other_discount: Yup.number().typeError('Other discount must be a number').max(0, 'Other discount must be less than 0'),
                                pricing_delivery_charge: Yup.number().typeError('Delivery charge must be a number').min(0, 'Delivery charge must be equal to or greater than 0'),
                                pricing_luxury_tax: Yup.number().typeError('Luxury tax must be a number').min(0, 'Luxury tax must be equal to or greater than 0'),
                                pricing_registration: Yup.number().typeError('Registration price must be a number').min(0, 'Registration price must be equal to or greater than 0'),
                                pricing_ctp: Yup.number().typeError('CTP must be a number').min(0, 'CTP must be equal to or greater than 0'),
                                pricing_premium_plates: Yup.number().typeError('Premium plates must be an number').min(0, 'Premium plates price must be equal to or greater than 0'),
                                pricing_stamp_duty: Yup.number().typeError('Stamp duty must be a number').min(0, 'Stamp duty must be equal to or greater than 0'),
                                vehicle_options: Yup.array().of(
                                    Yup.object().shape({
                                        label: Yup.string(),
                                        value: Yup.number().typeError('Value must be a number'),
                                    })
                                ),
                            })}
                            initialValues={{
                                email: dealerQuote?.email ? dealerQuote?.email : localStorage.getItem('dealership_email') ?? '',
                                dealership: dealerQuote?.dealership ? dealerQuote?.dealership : localStorage.getItem('dealership') ?? '',
                                attention_to: dealerQuote?.attention_to ? dealerQuote?.attention_to : localStorage.getItem('dealership_attention_to') ?? '',
                                fleet_status: dealerQuote?.fleet_status ?? '',
                                vehicle_availability: dealerQuote?.vehicle_availability ?? '',
                                colour_availability: dealerQuote?.colour_availability ?? '',
                                comments: dealerQuote?.comments ?? '',
                                pricing_retail_price: dealerQuote?.pricing_retail_price ?? '',
                                pricing_fleet_discount: dealerQuote?.pricing_fleet_discount ?? '',
                                pricing_bonus: dealerQuote?.pricing_bonus ?? '',
                                pricing_other_discount: dealerQuote?.pricing_other_discount ?? '',
                                pricing_sub_total: dealerQuote?.pricing_sub_total ?? '0',
                                pricing_delivery_charge: dealerQuote?.pricing_delivery_charge ?? '',
                                pricing_sub_total_after_delivery: dealerQuote?.pricing_sub_total_after_delivery ?? '0',
                                pricing_gst: dealerQuote?.pricing_gst ?? '0',
                                pricing_sub_total_after_gst: dealerQuote?.pricing_sub_total_after_gst ?? '0',
                                pricing_luxury_tax: dealerQuote?.pricing_luxury_tax ?? '',
                                pricing_registration: dealerQuote?.pricing_registration ?? '',
                                pricing_ctp: dealerQuote?.pricing_ctp ?? '',
                                pricing_premium_plates: dealerQuote?.pricing_premium_plates ?? '',
                                pricing_stamp_duty: dealerQuote?.pricing_stamp_duty ?? '',
                                pricing_total: dealerQuote?.pricing_total ?? '0',
                                vehicle_options: dealerQuote?.vehicle_options
                                    ? dealerQuote?.vehicle_options
                                    : [
                                          {
                                              label: '',
                                              value: '',
                                              hideRemove: true,
                                          },
                                      ],
                            }}
                        >
                            {({ values, setFieldValue }) => {
                                const onPricingChange = () => {
                                    const subtotal =
                                        (parseFloat(values.pricing_retail_price) || 0) + (ensureNegative(values.pricing_fleet_discount) || 0) + (ensureNegative(values.pricing_bonus) || 0) + (ensureNegative(values.pricing_other_discount) || 0);
                                    const subtotalAfterDelivery = subtotal + (parseFloat(values.pricing_delivery_charge) || 0);
                                    const gst = subtotalAfterDelivery * 0.1;
                                    const subtotalAfterGst = subtotalAfterDelivery + gst;
                                    const total =
                                        subtotalAfterGst +
                                        (parseFloat(values.pricing_luxury_tax) || 0) +
                                        (parseFloat(values.pricing_registration) || 0) +
                                        (parseFloat(values.pricing_ctp) || 0) +
                                        (parseFloat(values.pricing_ctp) * 0.1 || 0) +
                                        (parseFloat(values.pricing_premium_plates) || 0) +
                                        (parseFloat(values.pricing_stamp_duty) || 0);

                                    setFieldValue('pricing_fleet_discount', ensureNegative(values.pricing_fleet_discount) || 0);
                                    setFieldValue('pricing_bonus', ensureNegative(values.pricing_bonus) || 0);
                                    setFieldValue('pricing_other_discount', ensureNegative(values.pricing_other_discount) || 0);
                                    setFieldValue('pricing_sub_total', `$${thousandSeparated(subtotal)}`);
                                    setFieldValue('pricing_sub_total_after_delivery', `$${thousandSeparated(subtotalAfterDelivery)}`);
                                    setFieldValue('pricing_gst', `$${thousandSeparated(gst)}`);
                                    setFieldValue('pricing_sub_total_after_gst', `$${thousandSeparated(subtotalAfterGst)}`);
                                    setFieldValue('pricing_total', `$${thousandSeparated(total)}`);
                                };

                                return (
                                    <Form>
                                        <FormSection title="Dealership" isOpen={true}>
                                            <FieldGroup>
                                                <TextInput name="dealership" readOnly={true} label="Dealership" />

                                                <TextInput name="attention_to" readOnly={true} label="Attention To" />

                                                <TextInput name="email" readOnly={true} label="Email" />
                                            </FieldGroup>
                                        </FormSection>

                                        <FormSection title="Vehicle">
                                            <Box
                                                sx={{
                                                    mx: 'auto',
                                                    border: '1px solid',
                                                    borderColor: 'primary',
                                                    borderRadius: 2,
                                                    px: 4,
                                                    py: 3,
                                                    mb: 4,
                                                    textAlign: 'left',
                                                }}
                                            >
                                                <Headline as="h2" variant="h2" sx={{ color: 'primary', mb: 2, textAlign: 'left' }}>
                                                    Vehicle
                                                </Headline>

                                                <Text as="p" mb={3}>
                                                    {dealerQuote?.vehicle?.model_year} {dealerQuote?.vehicle?.make} {dealerQuote?.vehicle?.model} {dealerQuote?.vehicle?.variant}
                                                </Text>

                                                <Box>
                                                    <Box sx={{ display: 'flex' }}>
                                                        <Box>
                                                            <strong>Body Type:</strong>
                                                        </Box>
                                                        <Box ml={'auto'}>{dealerQuote?.vehicle?.body_type ?? '-'}</Box>
                                                    </Box>
                                                    <Box sx={{ display: 'flex' }}>
                                                        <Box>
                                                            <strong>Drive Type:</strong>
                                                        </Box>
                                                        <Box ml={'auto'}>{dealerQuote?.vehicle?.transmission ?? '-'}</Box>
                                                    </Box>
                                                    <Box sx={{ display: 'flex' }}>
                                                        <Box>
                                                            <strong>Engine Litres:</strong>
                                                        </Box>
                                                        <Box ml={'auto'}>{dealerQuote?.vehicle?.engine_litres ?? '-'}</Box>
                                                    </Box>
                                                    <Box sx={{ display: 'flex' }}>
                                                        <Box>
                                                            <strong>Fuel Type:</strong>
                                                        </Box>
                                                        <Box ml={'auto'}>{dealerQuote?.vehicle?.fuel_name ?? '-'}</Box>
                                                    </Box>
                                                    <Box sx={{ display: 'flex' }}>
                                                        <Box>
                                                            <strong>Colour Preference:</strong>
                                                        </Box>
                                                        <Box ml={'auto'}>{dealerQuote?.vehicle?.colour ?? '-'}</Box>
                                                    </Box>
                                                    <Box sx={{ display: 'flex' }}>
                                                        <Box>
                                                            <strong>Trim Preference:</strong>
                                                        </Box>
                                                        <Box ml={'auto'}>{dealerQuote?.vehicle?.interior_trim ?? '-'}</Box>
                                                    </Box>
                                                </Box>
                                            </Box>

                                            <FieldGroup>
                                                <Box sx={{ textAlign: 'left' }}>
                                                    <Select name="fleet_status" label="Fleet Status" options={fleetStatusOptions} includeEmpty={true} emptyLabel={'-- None --'} />
                                                </Box>

                                                <TextInput name="vehicle_availability" label="Vehicle Availability" />

                                                <TextInput name="colour_availability" label="Colour Availability" placeholder="Colour / Trim" />
                                                {dealerQuote?.vehicle?.colour || dealerQuote?.vehicle?.interior_trim ? (
                                                    <Text as="p" sx={{ fontSize: 0, fontWeight: 'bold' }}>{`Preferred Colour: ${dealerQuote?.vehicle?.colour ?? 'No colour preference'} / ${
                                                        dealerQuote?.vehicle?.interior_trim ?? 'No trim preference'
                                                    }`}</Text>
                                                ) : null}
                                            </FieldGroup>
                                        </FormSection>

                                        <FormSection title="Vehicle Price">
                                            <Text
                                                as="p"
                                                variant="body"
                                                sx={{
                                                    fontSize: ['10px', '12px'],
                                                    color: 'primary',
                                                    textAlign: 'left',
                                                    mb: 3,
                                                }}
                                            >
                                                Please enter all prices excluding GST.
                                            </Text>
                                            <FieldGroup>
                                                <Grid
                                                    sx={{
                                                        gridTemplate: 'auto',
                                                        mb: 3,
                                                    }}
                                                >
                                                    <Number name="pricing_retail_price" label="Retail Price" groupSx={{ mb: 0 }} onKeyUp={onPricingChange} />
                                                </Grid>

                                                <Grid
                                                    sx={{
                                                        gridTemplate: 'auto / repeat(3, 1fr)',
                                                        mb: 3,
                                                    }}
                                                >
                                                    <Number name="pricing_fleet_discount" label="Fleet Discount" groupSx={{ mb: 0 }} onKeyUp={onPricingChange} allowNegative={true} />

                                                    <Number name="pricing_bonus" label="Bonus Discount" groupSx={{ mb: 0 }} onKeyUp={onPricingChange} allowNegative={true} />

                                                    <Number name="pricing_other_discount" label="Other Discount" groupSx={{ mb: 0 }} onKeyUp={onPricingChange} allowNegative={true} />
                                                </Grid>

                                                <TextInput name="pricing_sub_total" label="Sub Total" readOnly={true} />
                                            </FieldGroup>
                                        </FormSection>

                                        <FormSection title="Vehicle Options">
                                            <Text
                                                as="p"
                                                variant="body"
                                                sx={{
                                                    fontSize: ['10px', '12px'],
                                                    color: 'primary',
                                                    textAlign: 'left',
                                                    mb: 3,
                                                }}
                                            >
                                                Please review any selected vehicle options and provide prices excluding GST.
                                            </Text>
                                            <FieldGroup>
                                                <Repeater
                                                    name="vehicle_options"
                                                    apiName="vehicle_options"
                                                    defaultRowValue={{
                                                        type: '',
                                                        value: '',
                                                        hideRemove: false,
                                                    }}
                                                    rowSx={{
                                                        display: 'grid',
                                                        gridTemplate: `auto/1fr 1fr ${values?.vehicle_options && values?.vehicle_options.length > 1 ? '20px' : ''}`,
                                                        gridGap: 3,
                                                        alignItems: 'center',
                                                    }}
                                                    fields={[
                                                        {
                                                            type: 'select',
                                                            label: 'Type',
                                                            name: 'type',
                                                            options: vehicleOptions ?? [],
                                                        },
                                                        {
                                                            name: 'value',
                                                            label: 'Value',
                                                            type: 'text',
                                                        },
                                                    ]}
                                                    showRemoveText={false}
                                                />
                                            </FieldGroup>
                                        </FormSection>

                                        <FormSection title="Vehicle On Roads / Government Charges">
                                            <Text
                                                as="p"
                                                variant="body"
                                                sx={{
                                                    fontSize: ['10px', '12px'],
                                                    color: 'primary',
                                                    textAlign: 'left',
                                                    mb: 3,
                                                }}
                                            >
                                                Please enter all prices excluding GST.
                                            </Text>

                                            <FieldGroup>
                                                <Number name="pricing_delivery_charge" label="Delivery Charge" onKeyUp={onPricingChange} />

                                                <TextInput name="pricing_sub_total_after_delivery" label="Sub Total (After Delivery)" readOnly={true} />
                                            </FieldGroup>

                                            <FieldGroup>
                                                <TextInput name="pricing_gst" label="GST" readOnly={true} />

                                                <TextInput name="pricing_sub_total_after_gst" label="Sub Total (After GST)" readOnly={true} />
                                            </FieldGroup>

                                            <FieldGroup>
                                                <Grid
                                                    sx={{
                                                        gridTemplate: 'auto / repeat(2, 1fr)',
                                                        mb: 3,
                                                    }}
                                                >
                                                    <Number name="pricing_luxury_tax" label="Luxury Tax" groupSx={{ mb: 0 }} onKeyUp={onPricingChange} />

                                                    <Number name="pricing_registration" label="Registration" groupSx={{ mb: 0 }} onKeyUp={onPricingChange} />

                                                    <Number name="pricing_ctp" label="CTP" groupSx={{ mb: 0 }} onKeyUp={onPricingChange} />

                                                    <Number name="pricing_premium_plates" label="Premium Plates" groupSx={{ mb: 0 }} onKeyUp={onPricingChange} />
                                                </Grid>

                                                <Number name="pricing_stamp_duty" label="Stamp Duty" onKeyUp={onPricingChange} />

                                                <TextInput name="pricing_total" label="Total (Inc GST)" readOnly={true} />
                                            </FieldGroup>
                                        </FormSection>

                                        <Text
                                            as="p"
                                            variant="body"
                                            sx={{
                                                fontSize: ['10px', '12px'],
                                                color: 'primary',
                                                textAlign: 'left',
                                                mt: 3,
                                                mb: 3,
                                            }}
                                        >
                                            Please provide any extra optional equipment already included on the vehicle or pricing notes in the field below:
                                        </Text>

                                        <TextArea label="Additional Comments" name="comments" groupSx={{ mt: 3 }} />

                                        {recaptchaEnabled && <RecaptchaField field="recaptcha" name="recaptcha" inputRef={recaptchaRef} />}
                                        {dealerQuote?.procurement_fee > 0 ? (
                                            <Text as="p" sx={{ fontWeight: 'bold' }} mb={4}>
                                                Please note a procurement fee applies to this order ${thousandSeparated(dealerQuote?.procurement_fee)} inc. GST
                                            </Text>
                                        ) : null}

                                        <Submit text="Submit" sx={{ width: 'full' }} />
                                    </Form>
                                );
                            }}
                        </Formik>
                    </Box>
                </BoxMotion>
            );
        } else {
            return (
                <BoxMotion key="confirmation">
                    <Headline sx={{ color: 'primary', mb: 3 }}>Dealer Quote</Headline>

                    <Text as="p" variant="lead" mb={4}>
                        Thank you for submitting your vehicle match for quote #{data?.quote_id}.
                    </Text>

                    {data?.can_submit_more ? (
                        <Text as="p">
                            <Button onClick={handleReloadForm} sx={{ cursor: 'pointer', fontWeight: 'bold' }}>
                                Supply another vehicle match
                            </Button>
                        </Text>
                    ) : (
                        <Text as="p">Vehicle limit reached for this quote.</Text>
                    )}
                </BoxMotion>
            );
        }
    };

    return (
        <Container>
            <ContentBox
                sx={{
                    textAlign: 'center',
                    maxWidth: 660,
                    mx: 'auto',
                    p: [4, 6],
                    minHeight: '416px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                {renderContent()}
            </ContentBox>
        </Container>
    );
};
