import { AppContext } from 'context/AppContext';
import { useFormikContext } from 'formik';
import { useContext, useEffect } from 'react';

export const CheckValid = ({ sectionIndex, groupIndex, required }) => {
    const { dispatch } = useContext(AppContext);
    const { isValid } = useFormikContext();

    useEffect(() => {
        dispatch({
            type: 'SET_GROUP_VALID',
            payload: { groupIndex, sectionIndex, isValid: isValid },
        });
        // eslint-disable-next-line
    }, [isValid]);

    return isValid;
};

CheckValid.defaultProps = {
    required: true,
};
