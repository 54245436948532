import { Box } from '@theme-ui/components';

import { Button } from 'components/Common/Button';

export const SubmitApplicationButton = ({
    sx,
    statusId,
    onStageClick,
    buttonText,
    isComplete,
    stageIndex,
    isLastStage,
    ...props
}) => {
    if (isLastStage && !isComplete) {
        return '';
    }

    return (
        <Box
            sx={{
                bg: 'white',
                textAlign: ['center', null, null, 'left'],
                position: ['fixed', null, null, 'relative'],
                bottom: 0,
                zIndex: 10,
                px: 4,
                width: ['100%', null, null, 'auto'],
                py: [3, null, null, 1],
                ...sx,
            }}
        >
            <Button
                sx={{ width: ['100%', null, null, 'auto'] }}
                onClick={() =>
                    onStageClick({ index: stageIndex, submission: isComplete })
                }
            >
                {isComplete ? 'Submit Application' : 'Continue to next step'}
            </Button>
        </Box>
    );
};

SubmitApplicationButton.defaultProps = {
    submission: true,
    stageIndex: 0,
};
