import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

import api from 'config/api';

export const useSubmitDealerQuote = () => {
    const dealerQuote = useMutation(async (values) => {
        try {
            let response = await api.post(`/dealerquote`, values);
            return response.data;
        } catch (e) {
            toast.error(e.response?.data?.message, {
                toastId: 'dealerquote-error'
            });
            return null;
        }
    });

    return dealerQuote;
};
