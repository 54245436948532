/** @jsxImportSource theme-ui */
import { Box, Text } from '@theme-ui/components';
import { Themed } from '@theme-ui/mdx';
import { useFormikContext } from 'formik';
import { motion } from 'framer-motion';
import _get from 'lodash.get';

import { thousandSeparated } from 'utils/formatting';

const BoxMotion = motion(Box);

export const Total = ({
    title,
    name,
    content,
    fields,
    property,
    sx,
    ignoreIfFalse,
    ...props
}) => {
    const { values } = useFormikContext();
    let total = 0;

    if (!fields || !Array.isArray(fields)) {
        return 'Missing fields property';
    }

    fields.forEach((field) => {
        let value = _get(values, field);
        let countFields = true;

        if (ignoreIfFalse) {
            // ignore this field if specified boolean is set to false (e.g other monthly income)
            countFields = ignoreIfFalse.some((item) => {
                if (_get(values, item.boolean) === false && item.field === field) {
                    return false;
                }

                return true;
            });
        }

        if (countFields && value !== undefined) {
            if (Array.isArray(value)) {
                value.forEach((row) => (total = total + parseInt(row[property] || 0)));
            } else {
                total = total + parseInt(value || 0);
            }
        }

        // check if it's a repeater (array)
    });

    return (
        <BoxMotion
            initial="visible"
            animate={total > 0 ? 'visible' : 'hidden'}
            exit="hidden"
            key={name}
            variants={{
                visible: {
                    opacity: 1,
                    height: 'auto',
                    transitionEnd: {
                        overflow: 'visible',
                    },
                },
                hidden: {
                    opacity: 0,
                    height: 0,
                    transitionEnd: {
                        overflow: 'hidden',
                    },
                },
            }}
            sx={{ overflow: 'hidden' }}
            {...props}
        >
            <Box
                sx={{
                    bg: 'white',
                    p: 4,
                    boxShadow: '0px 4px 30px rgba(40, 39, 129, 0.15)',
                    borderRadius: '15px',
                    width: '100%',
                    mb: 4,
                    textAlign: 'center',
                    ...sx,
                }}
            >
                {title && (
                    <Text as="p" variant="label" color="secondary" sx={{ mb: 2 }}>
                        {title}
                    </Text>
                )}

                <Themed.h2 sx={{ m: 0 }}>${thousandSeparated(total)}</Themed.h2>
            </Box>
        </BoxMotion>
    );
};
