import { Text } from '@theme-ui/components';

export const Headline = ({ children, sx, ...props }) => {
    return (
        <Text
            as="h1"
            variant="headline"
            sx={{
                color: 'secondary',
                textAlign: 'center',
                mb: 5,
                lineHeight: 0.95,
                ...sx,
            }}
            {...props}
        >
            {children}
        </Text>
    );
};
