import { useCountries } from 'hooks/useCountries';
import { Select } from '../Select';

export const Country = ({ ...props }) => {
    const { data: options } = useCountries({
        select: (data) =>
            data.map((country) => ({ label: country.name, value: country.country_id })),
    });

    return <Select options={options} isReadonly={true} {...props}></Select>;
};
