import { useMutation, useQueryClient } from 'react-query';
import _get from 'lodash.get';
import _set from 'lodash.set';
import produce from 'immer';
import { toast } from 'react-toastify';

import api from 'config/api';

export const useSubmitQuoteData = () => {
    const queryClient = useQueryClient();

    return useMutation(
        async ({
            name,
            value,
            repeater,
            callback,
            uploadSettled,
            onError,
            quoteUuid,
            notForm,
        }) => {
            let formData = new FormData();

            // remove the opening and closing "['" and "']" as the server cannot handle this
            // but they need to be set like this on the frontend  so we can keep for the formik values data structure flat (not a nested object)

            if (!notForm) {
                // we need to handle repeaters differently and send all the field data at once
                if (!repeater) {
                    formData.append(name, value);
                } else if (value) {
                    Array.isArray(value) &&
                        value.forEach((row, index) => {
                            if (!row) {
                                return;
                            }

                            const objKeys = Object.keys(row);
                            objKeys.forEach((key) => {
                                formData.append(`${name}[${index}][${key}]`, row[key]);
                            });
                        });
                }
            }

            try {
                let response = await api.post(`/quote/${quoteUuid}`, formData, {
                    headers: { 'Content-Type': 'multipart/form-data' },
                });

                if (response.data.success) {
                    callback && (await callback(_get(response.data.data, name)));
                    uploadSettled && uploadSettled();

                    toast.info('Quote progress saved.', {
                        toastId: 'quote-saved',
                    });
                }

                return response;
            } catch (e) {
                const { status } = e.response;
                // check error status - mainly for file size errors, as they want get caught in try/catch block
                e.response.data?.errors && onError && onError(e.response.data.errors);

                switch (status) {
                    case 413:
                        onError &&
                            onError('File size limit exceeded. Must be below 10MB.');
                        break;
                    default:
                        break;
                }
            }
        },
        {
            onMutate: async (data) => {
                // await queryClient.cancelQueries('quote');

                // don't set the value if it's a file - as we set the URL returned above in callback
                if (data.type === 'file') {
                    return;
                }

                const previousQuoteData = queryClient.getQueryData('quote');
                const nextState = produce(previousQuoteData, (draftState) => {
                    _set(draftState, data.name, data.value);
                });
                queryClient.setQueryData('quote', nextState);
            },
            onSuccess: async (data) => {
                await queryClient.cancelQueries('quote');

                if (data?.data?.success) {
                    queryClient.setQueryData('quote', (oldData) => {
                        const nextData = produce(oldData, (draftState) => {

                            // only update the required fields and quote status ID
                            // if we update the whole object and the user continues typing in fields before the data is saved, it will be overwritten with this response
                            if (draftState !== undefined){
                                draftState['required_fields'] =
                                    data?.data?.data.required_fields;

                                draftState['quote_status_id'] =
                                    data?.data?.data.quote_status_id;
                            }

                        });

                        return nextData;
                    });
                }
            },
        }
    );
};
