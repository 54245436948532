import { Box, Text } from '@theme-ui/components';
import { FormFields } from '../FormFields';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import { checkCondition } from '../FormSections/helpers';
import { getGroupData } from '../FormSection/helpers';
import { CheckValid } from '../FormSection/CheckValid';
import { AnimatePresence, motion } from 'framer-motion';

export const FormGroup = ({
    group: { title, description, condition, whiteBg, ...group },
    sectionIndex,
    groupIndex,
    quoteData,
    isOpen,
    ...props
}) => {
    const groupData = getGroupData(group, quoteData);

    const getBorderColor = (isValid, whiteBg) => {
        if (!isValid) {
            return '#fc111d';
        } else if (whiteBg) {
            return 'white';
        }

        return 'offWhite';
    };

    if (condition?.field || condition?.fields) {
        if (!checkCondition(condition, quoteData)) {
            return '';
        }
    }

    return (
        <Formik
            enableReinitialize
            initialValues={groupData.initialValues}
            validationSchema={Yup.object(groupData.validationSchema)}
            validateOnMount={true}
            validateOnChange={true}
        >
            {({ isValid }) => {
                return (
                    <>
                        <CheckValid
                            sectionIndex={sectionIndex}
                            groupIndex={groupIndex}
                            required={group.required}
                        />

                        <AnimatePresence initial={false}>
                            {isOpen && (
                                <motion.div
                                    key={sectionIndex}
                                    initial="collapsed"
                                    animate="open"
                                    exit="collapsed"
                                    variants={{
                                        open: {
                                            opacity: 1,
                                            transitionEnd: {
                                                overflow: 'visible',
                                            },
                                        },
                                        collapsed: {
                                            opacity: 1,
                                            overflow: 'hidden',
                                            transitionEnd: {
                                                overflow: 'hidden',
                                            },
                                        },
                                    }}
                                    // transition={{
                                    //     duration: 0.5,
                                    // }}
                                    sx={{
                                        bg: 'white',
                                        pointerEvents: isOpen ? 'auto' : 'none',
                                        zIndex: isOpen ? -1 : 2,
                                        overflow: 'hidden',
                                    }}
                                >
                                    <Form>
                                        <Box
                                            sx={{
                                                border: '1px solid',
                                                borderColor: getBorderColor(
                                                    isValid,
                                                    whiteBg
                                                ),
                                                bg: whiteBg ? 'white' : 'offWhite',
                                                borderRadius: 4,
                                                px: whiteBg ? 0 : 3,
                                                pt: 4,
                                                pb: 3,
                                                mb: 3,
                                            }}
                                        >
                                            {title && (
                                                <Text
                                                    as="p"
                                                    variant="lead"
                                                    sx={{ mb: description ? 2 : 3 }}
                                                >
                                                    {title}
                                                </Text>
                                            )}

                                            {description && (
                                                <Text
                                                    as="p"
                                                    variant={
                                                        group.descriptionLarge
                                                            ? 'body'
                                                            : 'label'
                                                    }
                                                    sx={{
                                                        mb: '22px',
                                                        color: group.descriptionLarge
                                                            ? 'primary'
                                                            : 'grayDark',
                                                    }}
                                                >
                                                    {description}
                                                </Text>
                                            )}

                                            <FormFields {...group} />
                                        </Box>
                                    </Form>
                                </motion.div>
                            )}
                        </AnimatePresence>
                    </>
                );
            }}
        </Formik>
    );
};

FormGroup.defaultProps = { whiteBg: false };
