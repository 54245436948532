import styled from '@emotion/styled';
import { motion } from 'framer-motion';

import { theme } from 'theme';
import { StageHeader } from '../StageHeader';

export const StageHeaderStyled = styled(motion(StageHeader))`
    svg {
        transition: transform 0.5s ease;
    }

    p {
        transition: all 0.5s ease;
    }

    &:hover,
    &:focus {
        p {
            color: ${(props) => (props.modalView ? 'inherit' : theme.colors.secondary)};
        }

        svg.arrow {
            transform: translateX(3px);
        }
    }
`;
