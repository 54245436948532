import { Box, Text } from '@theme-ui/components';
import { useField } from 'formik';
import { RadioGroupStyled } from './styled';

export const RadioGroup = ({ name, apiName, label, options, sx, onChange, ...props }) => {
    // eslint-disable-next-line
    const [field, meta, { setValue }] = useField({ name });

    return (
        <Box as="fieldset" sx={{ border: 'none', p: 0, mb: 4 }}>
            <Text as="legend" variant="lead" mb="11px">
                {label}
            </Text>

            <RadioGroupStyled sx={{ ...sx }}>
                {options.map((option, index) => {
                    return (
                        <label key={index}>
                            <input
                                {...field}
                                type="radio"
                                name={name}
                                checked={field.value === option.value}
                                onChange={() => {
                                    onChange &&
                                        onChange({ name: apiName, value: option.value });
                                    setValue(option.value);
                                }}
                            />

                            <Text
                                as="span"
                                variant="label"
                                sx={{
                                    borderRadius: [15, 30],
                                    padding: ['6px 30px', '6px 35px'],
                                }}
                            >
                                {option.label}
                            </Text>
                        </label>
                    );
                })}
            </RadioGroupStyled>
        </Box>
    );
};

RadioGroup.defaultProps = {
    name: '',
    options: [],
};
